<app-header></app-header>

<!-- Appointment Start -->
<div
class="container-fluid appointment py-5 wow fadeIn"
data-wow-delay="0.1s"
>
<div class="container py-5">
  <div class="row g-5">
    <div class="col-lg-5 col-md-6 wow fadeIn" data-wow-delay="0.3s">
      <div class="border-start border-5 border-primary ps-4 mb-5">
        <h6 class="text-white text-uppercase mb-2">Olvido de contraseña</h6>
        <h1 class="display-6 text-white mb-0">
          Ingresá tu correo.
        </h1>
      </div>
      <!-- <p class="text-white mb-0">
        Dejanos tus datos y te enviaremos mas información.
      </p> -->
    </div>
    <div class="col-lg-7 col-md-6 wow fadeIn" data-wow-delay="0.5s">
      
        <div class="p-4">
            <!-- <h4 class="mt-3 text-center text-white">Login</h4> -->
            <div class="row g-3 mt-2">
                <div class="col-md-12">
                    <div class="alert alert-danger" role="alert" *ngIf="alertUser">
                        <p style="margin-left: 10px;">Debes introducir tu correo electrónico</p> 
                    </div>
                    <div class="form-floating">
                        <input 
                            name="email"
                            [(ngModel)]="email"
                            type="email"
                            class="form-control border-0 bg-light transparent-90"
                            id="email"
                            placeholder="Correo Electrónico"/>
                        <label for="email">Correo Electrónico</label>
                    </div>
                </div>
                <div class="alert alert-success" role="alert" *ngIf="alertOK">
                    <p style="margin-left: 10px;">Hemos enviado una nueva contraseña a tu correo.</p> 
                </div>
                <div class="alert alert-danger" role="alert" *ngIf="alertError">
                    <p style="margin-left: 10px;">{{ errorMessage }}</p> 
                </div>
                <!-- <div class="col-md-12">
                    <div class="alert alert-danger" role="alert" *ngIf="alertPassword">
                    <p style="margin-left: 10px;">Debes introducir tu contraseña</p> 
                    </div>
                    <div class="form-floating">
                        <input 
                            name="pass"
                            [(ngModel)]="pass"
                            type="password"
                            class="form-control border-0 bg-light transparent-90"
                            id="pass"
                            placeholder="Contraseña"
                        />
                        <label for="pass">Contraseña</label>
                    </div>
                </div> -->
            </div>
            <div class="row g-3 mt-3" style="text-align: center;">
                <button class="btn btn-primary btn-lg block" (click)="process()">Generar nueva contraseña</button>
            </div>
        </div>

    </div>
  </div>
</div>
</div>
<!-- Appointment End -->

<app-footer></app-footer>


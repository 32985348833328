import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../../services/users.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-supplier-sidemenu',
  templateUrl: './supplier-sidemenu.component.html',
  styleUrls: ['./supplier-sidemenu.component.css']
})
export class SupplierSidemenuComponent implements OnInit {
  menu = 2;
  active1 = '';
  active2 = '';
  menuOptions = [
    {id:1, name: 'Home', activeClass: 'active', iconClass: 'fs-4 bi-house', active: false},
    {id:2, name: 'Perfil', activeClass: '', iconClass: 'fs-4 bi-person', active: true},
    {id:3, name: 'Certificados', activeClass: '', iconClass: 'fs-4 bi-patch-check', active:false},
    {id:4, name: 'Compras', activeClass: '', iconClass: 'fs-4 bi-bag', active: false},
    {id:5, name: 'Planes', activeClass: '', iconClass: 'fs-4 bi-table', active: false},
  ];

  constructor(private userService: UsersService,
              private router: Router) { }

  ngOnInit(): void {
  }

  menuSelect(opt){
    this.menu = opt;
    this.menuOptions.forEach(item => {
      item.activeClass = '';
      if (item.id === opt) {
        item.activeClass = 'active';
      }
    })
  }

  logout(){
    this.userService.logout();
    this.router.navigate(['/home']);
  }

}

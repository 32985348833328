<app-header></app-header>

<!-- Page Header Start -->
<div
class="container-fluid page-header py-5 mb-5 wow fadeIn"
data-wow-delay="0.1s"
>
<div class="container text-center py-5">
  <h1 class="display-4 text-white animated slideInDown mb-4">A cerca de nosotros</h1>
  <nav aria-label="breadcrumb animated slideInDown">
    <ol class="breadcrumb justify-content-center mb-0">
      <li class="breadcrumb-item">
        <a class="text-white"  routerLink="/home" >Home</a>
      </li>
      <!-- <li class="breadcrumb-item">
        <a class="text-white" href="#">Pages</a>
      </li> -->
      <li class="breadcrumb-item text-primary active" aria-current="page">
        Quiénes somos
      </li>
    </ol>
  </nav>
</div>
</div>
<!-- Page Header End -->

<!-- About Start -->
<div class="container-xxl py-5">
<div class="container">
  <div class="row g-5">
    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
      <div
        class="position-relative overflow-hidden ps-5 pt-5 h-100"
        style="min-height: 400px"
      >
        <img
          class="position-absolute w-100 h-100"
          src="/assets/img/img-707x700-Limpieza_thumb_up.jpg"
          alt=""
          style="object-fit: cover"
        />
        <!-- <div
          class="position-absolute top-0 start-0 bg-white pe-3 pb-3"
          style="width: 200px; height: 200px"
        >
          <div
            class="d-flex flex-column justify-content-center text-center bg-primary h-100 p-3"
          >
            <h1 class="text-white">8+</h1>
            <h2 class="text-white">Años</h2>
            <h5 class="text-white mb-0">Brindando soluciones</h5>
          </div>
        </div> -->
      </div>
    </div>
    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
      <div class="h-100">
        <div class="border-start border-5 border-primary ps-4 mb-5">
          <h6 class="text-body text-uppercase mb-2">Quiénes somos</h6>
          <h1 class="display-6 mb-0">
            Soluciones de limpieza y servicios para tu hogar
          </h1>
        </div>
        <p>
          P-ARTICULAR es una empresa del grupo Particulares, con la misión de acercarte a los/las mejores profesionales de tareas generales del hogar y cuidado de personas.
          Creemos que la tecnología está al servicio de nuestros clientes y profesionales. En tiempos de pandemia hemos aprendido que hoy más que nunca la colaboración online es fundamental.
        </p>
        <p class="mb-4">
          Si sos un profesional de limpieza, cocina o cuidado de personas, <a href="">regístrate aquí</a> y aparecerás en las búsquedas de nuestros clientes quienes te podrán contactar directamente para requerir tus servicios.
        </p>
        <div class="border-top mt-4 pt-4">
          <div class="row g-4">
            <div class="col-sm-4 d-flex wow fadeIn" data-wow-delay="0.1s">
              <i
                class="fa fa-check fa-2x text-primary flex-shrink-0 me-3"
              ></i>
              <h6 class="mb-0">Puntualidad</h6>
            </div>
            <div class="col-sm-4 d-flex wow fadeIn" data-wow-delay="0.3s">
              <i
                class="fa fa-check fa-2x text-primary flex-shrink-0 me-3"
              ></i>
              <h6 class="mb-0">Calidad</h6>
            </div>
            <div class="col-sm-4 d-flex wow fadeIn" data-wow-delay="0.5s">
              <i
                class="fa fa-check fa-2x text-primary flex-shrink-0 me-3"
              ></i>
              <h6 class="mb-0">Profesionales verificados</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<!-- About End -->

<!-- Team Start -->
<div class="container-xxl py-5">
<div class="container">
  <div class="row g-5 align-items-end mb-5">
    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
      <div class="border-start border-5 border-primary ps-4">
        <h6 class="text-body text-uppercase mb-2">Nuestro equipo</h6>
        <h1 class="display-6 mb-0">Fundadores</h1>
      </div>
    </div>
    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
      <p class="mb-0">
        Profesionales con alta experienca en negocios orientados a servicios con muchos años en el mercado argentino.
      </p>
    </div>
  </div>
  <div class="row g-4">
    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
      <div class="team-item position-relative">
        <img class="img-fluid" src="/assets/img/CEO.jpg" alt="" />
        <div class="team-text bg-white p-4">
          <h5>Fernando Vera Del Barco</h5>
          <span>Fundador y CEO</span>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
      <div class="team-item position-relative">
        <img class="img-fluid" src="/assets/img/VP.jpg" alt="" />
        <div class="team-text bg-white p-4">
          <h5>Nicolas Mirande</h5>
          <span>Fundador y CFO</span>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
      <div class="team-item position-relative">
        <img class="img-fluid" src="/assets/img/CDO.png" alt="" />
        <div class="team-text bg-white p-4">
          <h5>Guillermo Argañaraz</h5>
          <span>Fundador y CDO</span> 
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<!-- Team End -->

<app-footer></app-footer>

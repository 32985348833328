import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../../services/users.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login-full',
  templateUrl: './login-full.component.html',
  styleUrls: ['./login-full.component.css']
})
export class LoginFullComponent implements OnInit {
  alertUser = false;
  alertPassword = false;
  email = '';
  pass = '';
  alertLogin = false;
  link = '';

  constructor(private userService: UsersService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params=>{
      if (params.link) {
        this.link = params.link;
      }
      
    });
  }

  login(){
    this.alertLogin = false;
    this.userService.login(this.email, this.pass,'','','','PROFESIONAL', '','')
      .then(res=>{
        console.log(res);
        if (!res) {
          this.alertLogin = true;
        } else {
          // window.location.reload();
          // this.modal.close()
          if (this.link !== '') {
            this.router.navigate(['/'+this.link]);
          } else {
            this.router.navigate(['/supplier-sidemenu']);
          }
          
        }
       
      })
  }

}

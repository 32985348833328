import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsersService } from './users.service';
import { environment } from '../../environments/environment.prod';

const URL2 = environment.url2;

@Injectable({
  providedIn: 'root'
})
export class StatescitiesService {

  constructor(private http: HttpClient,
    private usuarioService: UsersService) { }

    getStates() {
      // Retrieves staes for the registered country in the user profile
      const authorization = this.usuarioService.getUsuario().token;
      const headers = new HttpHeaders({
        Authorization: authorization
      });
  
      const api = URL2 + 'states';
      return this.http.get(api , {headers} );
    }
  
    getStatesByCountry(countryId) {
          // Retrieves staes for the receiving country
      const authorization = this.usuarioService.getUsuario().token;
      const headers = new HttpHeaders({
        Authorization: authorization
      });
  
      const api = URL2 + 'getStatesByCountry/' + countryId;
      // console.log(data);
      return this.http.get(api , {headers} );
      // return this.http.post(`${ URL }` , data , {headers} );
    }
  
    getCitiesByState(stateId: string) {
      const authorization = this.usuarioService.getUsuario().token;
      const headers = new HttpHeaders({
        Authorization: authorization
      });
      const api = URL2 + 'citiesByState/' + stateId;
      // console.log(data);
      return this.http.get(api , {headers} );
      // return this.http.post(`${ URL }` , data , {headers} );
    }
  
    getSepomex(stateId: string, cityId: string ){
      const authorization = this.usuarioService.getUsuario().token;
      const headers = new HttpHeaders({
        Authorization: authorization
      });
      const api = URL2 + 'sepomexCity/' + stateId + '/' + cityId;
      // console.log(data);
      return this.http.get(api , {headers} );
    }
  
    getSepomexByZipCode(zipCode: string ){
      // const authorization = this.usuarioService.getUsuario().token;
      const headers = new HttpHeaders({
        // Authorization: authorization
      });
      const api = URL2 + 'sepomexZip/' + zipCode;
      // console.log(data);
      return this.http.get(api , {headers} );
    }

    getSepomexByZipCountry(zipCode: string, countryId: string ){
      // const authorization = this.usuarioService.getUsuario().token;
      const headers = new HttpHeaders({
        // Authorization: authorization
      });
      const api = URL2 + 'sepomexZipCountry/' + zipCode + '/' + countryId;
      // console.log(data);
      return this.http.get(api , {headers} );
    }

    getSepomexById(sepomexId: string ){
      const authorization = this.usuarioService.getUsuario().token;
      const headers = new HttpHeaders({
        Authorization: authorization
      });
      const api = URL2 + 'sepomex/' + sepomexId;
      // console.log(data);
      return this.http.get(api , {headers} );
    }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/headers/header/header.component';
import { FooterComponent } from './components/headers/footer/footer.component';
import { AboutComponent } from './components/about/about.component';
import { ServicesComponent } from './components/ourservices/services.component';
import { ContactComponent } from './components/contact/contact.component';
import { AskQuotationComponent } from './components/customers/ask-quotation/ask-quotation.component';
import { ConnectionErrorComponent } from './components/helpers/connection-error/connection-error.component';
import { LoginComponent } from './components/auth/login/login.component';
import { FormsModule } from '@angular/forms';
import { FilepickerComponent } from './components/helpers/filepicker/filepicker.component';
import { ImageBlobPipe } from './pipes/image-blob.pipe';
import { DatePipe } from '@angular/common';
import { LegalTermsComponent } from './components/legal/legal-terms/legal-terms.component';
import { PrivacyStatementComponent } from './components/legal/privacy-statement/privacy-statement.component';
import { LandingFlyerComponent } from './components/landing/landing-flyer/landing-flyer.component';
import { SupplierRegisterComponent } from './components/suppliers/supplier-register/supplier-register.component';
import { SupplierSidemenuComponent } from './components/suppliers/supplier-sidemenu/supplier-sidemenu.component';
import { HeaderUserComponent } from './components/headers/header-user/header-user.component';
import { SupplierProfileComponent } from './components/suppliers/supplier-profile/supplier-profile.component';
import { SupplierPersonalDataComponent } from './components/suppliers/supplier-personal-data/supplier-personal-data.component';
import { SupplierCategoriesComponent } from './components/suppliers/supplier-categories/supplier-categories.component';
import { LoginFullComponent } from './components/auth/login-full/login-full.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ModalConfirmationComponent } from './components/helpers/modal-confirmation/modal-confirmation.component';
import { ChangePasswordComponent } from './components/auth/change-password/change-password.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    ServicesComponent,
    ContactComponent,
    AskQuotationComponent,
    ConnectionErrorComponent,
    LoginComponent,
    FilepickerComponent,
    ImageBlobPipe,
    LegalTermsComponent,
    PrivacyStatementComponent,
    LandingFlyerComponent,
    SupplierRegisterComponent,
    SupplierSidemenuComponent,
    HeaderUserComponent,
    SupplierProfileComponent,
    SupplierPersonalDataComponent,
    SupplierCategoriesComponent,
    LoginFullComponent,
    ForgotPasswordComponent,
    ModalConfirmationComponent,
    ChangePasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

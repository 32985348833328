<app-header></app-header>
<!-- Page Header Start -->
<div
class="container-fluid page-header py-5 mb-5 wow fadeIn"
data-wow-delay="0.1s"
>
<div class="container text-center py-5">
  <h1 class="display-4 text-white animated slideInDown mb-4">
    Aviso de Privacidad
  </h1>
  <nav aria-label="breadcrumb animated slideInDown">
    <ol class="breadcrumb justify-content-center mb-0">
      <li class="breadcrumb-item">
        <a class="text-white" href="index.html">Home</a>
      </li>
      <!-- <li class="breadcrumb-item">
        <a class="text-white" href="#">Pages</a>
      </li> -->
      <li class="breadcrumb-item text-primary active" aria-current="page">
        Aviso de Privacidad
      </li>
    </ol>
  </nav>
</div>
</div>
<!-- Page Header End -->

<!-- Conditions Start -->
<div class="container-xxl py-5">
    <div class="container">
        <div class="text-center">
            <h5>AVISO DE PRIVACIDAD.</h5>
            <br>
            <div class="" style="text-align: left">
              En preparación...
            </div>
        
        </div>
    </div>
</div>

<!-- Conditions End -->

<app-footer></app-footer>





<!-- Footer Start -->
<div
class="container-fluid bg-tertiary footer mt-5 pt-5 wow fadeIn"
data-wow-delay="0.1s"
>
<div class="container py-5">
  <div class="row g-5">
    <div class="col-lg-3 col-md-6">
      <h2 class="text-white mb-4">
        <!-- <i class="fa fa-building text-primary me-3"></i> -->
        <img src="/assets/img/logo-particulares.png"  style="width: 50px;" alt="">
        PARTICULARES
      </h2>
      <p>
        Empresa del grupo Particulares.
      </p>
      <!-- <div class="d-flex pt-2">
        <a class="btn btn-square btn-outline-primary me-1" href=""
          ><i class="fab fa-twitter"></i
        ></a>
        <a class="btn btn-square btn-outline-primary me-1" href=""
          ><i class="fab fa-facebook-f"></i
        ></a>
        <a class="btn btn-square btn-outline-primary me-1" href=""
          ><i class="fab fa-youtube"></i
        ></a>
        <a class="btn btn-square btn-outline-primary me-0" href=""
          ><i class="fab fa-linkedin-in"></i
        ></a>
      </div> -->
    </div>
    <div class="col-lg-3 col-md-6">
      <h4 class="text-light mb-4">Ubicación</h4>
      <p>
        <i class="fa fa-map-marker-alt me-3"></i>San Miguel de Tucumán, TUCUMÁN, ARGENTINA
      </p>
      <!-- <p><i class="fa fa-phone-alt me-3"></i>+0381 123123456</p> -->
      <p><i class="fa fa-envelope me-3"></i>info@particulares.com.ar</p>
    </div>
    <div class="col-lg-3 col-md-6">
      <h4 class="text-light mb-4">Enlaces rápidos</h4>
      <a class="btn btn-link" routerLink="/about" >Quiénes somos</a>
      <!-- <a class="btn btn-link" routerLink="/contact" >Contacto</a> -->
      <a class="btn btn-link" routerLink="/services" >Nuestros servicios</a>
      <a class="btn btn-link" routerLink="/legal-terms" >Términos y Condiciones</a>
      <a class="btn btn-link" routerLink="/privacy-statement" >Política de Privacidad</a>
      <a class="btn btn-link" href="" >Centro de Ayuda</a>
    </div>
    <!-- <div class="col-lg-3 col-md-6">
      <h4 class="text-light mb-4">Newsletter</h4>
      <p>Suscríbete a nuestro newletter para recibir novedades de nuestros servicios.</p>
      <div class="position-relative mx-auto" style="max-width: 400px">
        <input
          class="form-control bg-transparent w-100 py-3 ps-4 pe-5"
          type="text"
          placeholder="Tu email"
        />
        <button
          type="button"
          class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
        >
          Suscribir
        </button>
      </div>
    </div> -->
  </div>
</div>
<div class="container-fluid copyright">
  <div class="container">
    <div class="row">
      <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
        &copy; <a href="https://particulares.com.ar">Particulares</a>, All Right Reserved.
      </div>
      <div class="col-md-6 text-center text-md-end">
        <!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
        Designed By <a href="https://htmlcodex.com">HTML Codex</a>
        <br />Distributed By:
        <a href="https://themewagon.com" target="_blank">ThemeWagon</a>
      </div>
    </div>
  </div>
</div>
</div>
<!-- Footer End -->

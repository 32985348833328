import { Pipe, PipeTransform } from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'imageBlob'
})
export class ImageBlobPipe implements PipeTransform {

  constructor( private domSanitizer: DomSanitizer ) {}

  transform(file: any): SafeUrl {
    const imageBlob = window.URL.createObjectURL(file[0]);
    // this.imageURL = this.domSanitizer.bypassSecurityTrustUrl(imageBlob);
    return this.domSanitizer.bypassSecurityTrustUrl(imageBlob);
  }

}

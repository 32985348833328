import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../../services/users.service';
import { SuppliersService } from '../../../services/suppliers.service';
import { Usuario, Supplier, SupplierCheckList } from '../../../interfaces/interfaces';

@Component({
  selector: 'app-supplier-register',
  templateUrl: './supplier-register.component.html',
  styleUrls: ['./supplier-register.component.css']
})
export class SupplierRegisterComponent implements OnInit {
  userName = '';
  userEmail = '';
  userPhone = '';
  userLegalTermsAccepted = false;
  userId = '';
  userDNI = '';
  noEmail = false;

  supplierName = '';
  supplierLastName = '';
  supplierEmail = '';
  supplierPhone = '';
  supplierLegalTermsAccepted = false;
  supplierId = '';

  alertMessage = false;
  alertName = false;
  alertLastName = false;
  alertPhone = false;
  alertPhoneText = '';
  alertEmail = false;
  alertTerms = false;
  alertCode = false;
  alertCategories = false;
  alertDocumentation = false;
  alertFileSize = false;
  alertFileExtension = false;
  alertZipcode = false;
  successRegister = false;
  errorRegister = false;
  errorMessage = '';
  enableLogin = false;
  supplier: Supplier;
  supplierCheckList: SupplierCheckList;

  constructor(private userService: UsersService,
              private supplierService: SuppliersService) { }

  ngOnInit(): void {
  }

  process(){
    if (this.userName === '') {
      this.errorRegister = true;
      this.errorMessage = 'Tenés que indicar tu nombre';
    } else {
      if (!this.validPhone(this.userPhone)) {
        this.errorRegister = true;
        this.errorMessage = 'Tu celular debe tener formato código de area + número: ejemplo si sos de San Miguel de Tucumán , tu número debe comenzar con 381 y seguido de tu número de celular (sin el 15)';
      } else {
        if (!this.supplierLegalTermsAccepted) {
          this.errorRegister = true;
          this.errorMessage = 'Debes aceptar los Términos y Condiciones';
        } else {
          if (!this.validDNI(this.userDNI)) {
            this.errorRegister = true;
            this.errorMessage = 'Tu DNI debe tener 8 dígitos.';
          } else {
            this.register();
          }
        }
      }
    }
  }

  register(){
    this.clearCache();
    this.successRegister = false;
    this.errorRegister = false;
    // this.validarEmail(this.userEmail);

    let usuario: Usuario = {
      avatar: 'av-1.png',
      nombre: this.userName,
      name: this.userName,
      email: this.userEmail,
      password: '',
      pass: '',
      userType: 'PROFESIONAL',
      enabled: 1,
      background_image: '',
      alias: '',
      register_type: 'AUTOMATIC',
      country_id: 'AR',
      DNI: this.userDNI,
      cellPhone: this.userPhone
    };
    this.userService.registro(usuario)
      .then(res=>{
        console.log(res);
        
        if (res['code'] === '200') {
          console.log('REGISTRO Exitoso.');
          this.successRegister = true;
          this.login();
        } else {
          this.errorRegister = true;
          console.log(res);
          this.errorMessage = res['msg'];
          if (res['code'] === '501') {
            this.enableLogin = true;
          }
          
        }
      });
  }

  login(){
    const email     = this.userEmail;
    const password  = ''; 
    const loginType = 'automatic';
    const alias     = '';
    const name      = this.supplierName;
    const userType  = 'PROFESIONAL'; 
    const registerType = 'AUTOMATIC';
    const avatar    = '';

    this.userService.login(email, password, loginType, alias, name, userType,registerType, avatar)
      .then(res => {
        console.log('LOGIN:');
        console.log(res);
        
        if (res) {
          // this.updateGeneralData();
          this.updateGeneralData();
        }
      })
  }

  clearCache(){
    localStorage.removeItem('userId');
    localStorage.removeItem('alias');
    localStorage.removeItem('avatar');
    localStorage.removeItem('email');
    localStorage.removeItem('enabled');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('userName');
    localStorage.removeItem('token');
  }

  updateGeneralData(){
    // console.log(this.supplierPhone);
    const userId = localStorage.getItem('userId');
    if (userId) {
      this.supplierService.getSupplierDetails(userId)
        .subscribe(res=>{
          if (res['code'] === '200') {
            this.supplier = res['data'];
            const generalData = {
              names: this.userName,
              last_names: '',
              phone_mobile: this.userPhone,
              // stateId: this.selectedSepomex.idEstado,
              // cityId: this.selectedSepomex.idMunicipio,
              // zip_code: this.zipCode,
              // sepomex_id: this.selectedSepomex.id,
              // address: this.supplierAddress,
              // VAT_typeId: this.VAT_typeId
            };
            // console.log(generalData);
            this.supplierService.updateSupplierData(generalData)
              .then(res => {
                console.log(res);
                this.supplier = res['data'];
                this.supplierCheckList = {
                  id: this.supplier.id,
                  new: this.supplier.new,
                  accepted_terms_conditions: '1',
                  accepted_privacy_statement: '1',
                  completed_personal_data: '0',
                  completed_documentation: '0',
                  completed_categories: '0'
                };
                this.supplierService.updateSupplierLegalTerms(this.supplierCheckList)
                  .then(res2=>{
                    // this.next();
                    this.logout();
                  });
              })
          }
        })
    }

    
  }

  getSupplierData(){
    const userId = localStorage.getItem('userId');
    if (userId) {
      this.supplierService.getSupplierDetails(userId)
        .subscribe(res=>{
          if (res['code'] === '200') {
            this.supplier = res['data'];
          }
        })
    }
  }

  logout(){
    this.userService.logout();
  }

  validEmail(email){
    const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if (!regex.test(email)) {
      return false;
    } else {
      return true;
    }
  }

  validDNI(dni){
    const regex = /^\d{8}(?:\d{2})?$/i;
    if (!regex.test(dni)) {
      return false;
    } else {
      return true;
    }
  }

  validPhone(phone){
    const regex = /^\d{10}(?:\d{2})?$/i;
    if (!regex.test(phone)) {
      return false;
    } else {
      return true;
    }
  }

  

}

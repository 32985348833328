<app-header></app-header>
<!-- Page Header Start -->
<div
class="container-fluid page-header py-5 mb-5 wow fadeIn"
data-wow-delay="0.1s"
>
<div class="container text-center py-5">
  <h1 class="display-4 text-white animated slideInDown mb-4">
    Términos y condiciones de contratación
  </h1>
  <nav aria-label="breadcrumb animated slideInDown">
    <ol class="breadcrumb justify-content-center mb-0">
      <li class="breadcrumb-item">
        <a class="text-white" href="index.html">Home</a>
      </li>
      <!-- <li class="breadcrumb-item">
        <a class="text-white" href="#">Pages</a>
      </li> -->
      <li class="breadcrumb-item text-primary active" aria-current="page">
        Términos y condiciones de contratación
      </li>
    </ol>
  </nav>
</div>
</div>
<!-- Page Header End -->

<!-- Conditions Start -->
<div class="container-xxl py-5">
    <div class="container">
        <div class="text-center">
            <h5>TERMINOS Y CONDICIONES DE CONTRATACIÓN.</h5>
            <br>
            <div class="" style="text-align: left">
              <p>P-ARTICULAR (Para Articular) es un producto de NIFERNA Developments, ubicado en Yerba Buena, Tucumán, Argentina (en adelante “P-ARTICULARES”).</p>
        
              <h5>1.	DEFINICIONES PREVIAS</h5>
              <p>1.1.	<u>EMPLEADOR:</u> Se entenderá por al sujeto que se registre en el sistema de P-ARTICULAR bajo tal condición con la finalidad de visualizar o contratar TRABAJADORAS DE CASAS PARTICULARES en los términos de la Ley 26.844 (REGIMEN ESPECIAL DE CONTRATO DE TRABAJO PARA EL PERSONAL DE CASAS PARTICULARES).</p>
              <p>1.2.	<u>TRABAJADORA:</u> Refiere al sujeto que se registre en el sistema de P-ARTICULAR bajo tal condición con la finalidad de ser contratada para la realización de las tareas referidas en el art. 2º de la Ley 26.844 (REGIMEN ESPECIAL DE CONTRATO DE TRABAJO PARA EL PERSONAL DE CASAS PARTICULARES).</p>
              <p>1.3.	<u>POSTULANTE:</u> Sujeto cuya autorización se encuentra pendiente y en revisión de P-ARTICULAR para la utilización del sistema.</p>
              <p>1.4.	<u>USUARIOS:</u> Sujeto que utiliza el sistema provisto por P-ARTICULAR. Término utilizado en términos generales e incluye a EMPLEADORES, TRABAJADORES Y POSTULANTES de forma indistinta.</p>
              
              <h5>2.	INFORMACIÓN</h5>
              <p>2.1.	La información suministrada por los USUARIOS podrá ser utilizada y publicada por P-ARTICULAR sin restricción de naturaleza alguna.<p>
              
              <h5>3.	CONVERSACIONES Y CHATS</h5>
              <p>3.1.	Las conversaciones y/o chats entre los USUARIOS quedará registrada en el sistema de P-ARTICULAR y no poseen carácter privado.</p>
              
              <h5>4.	DECLARACIÓN JURADA</h5>
              <p>4.1.	Los USUARIOS asumen la responsabilidad legal por los datos que consignen en el sistema, las que poseerán carácter de declaración jurada.</p>
        
              <h5>5.	REGISTRO</h5>
              <p>5.1.	Los USUARIOS serán identificados con un número de inscripción coincidente con su Documento Nacional de Identidad, Libreta Cívica o de enrolamiento, Pasaporte, o el instrumento identificatorio del que se trate.</p>
        
              <h5>6.	PAGOS</h5>
              <p>6.1.	Los mecanismos de pago, porcentajes, comisiones, y las condiciones comerciales serán establecidas por P-ARTICULAR.</p>
        
              <h5>7.	VISUALIZACIÓN</h5>
              <p>7.1.	El pago de los aranceles dispuestos por P-ARTICULAR otorgarán a los USUARIOS el derecho de visualización de la información vigente en la plataforma por el plazo acordado.</p>
        
              <h5>8.	AVISO DE CONTRATACIÓN</h5>
              <p>8.1.	El EMPLEADOR que concretase una contratación de una TRABAJADORA asume la obligación de notificar a P-ARTICULAR la concreción del negocio jurídico.</p>
              <p>8.2.	La infracción al punto anterior autorizará a P-ARTICULAR a aplicar las penalidades que estimara procedentes.</p>
        
              <h5>9.	PENALIDADES</h5>
              <p>9.1.	P-ARTICULAR podrá aplicar las penalidades que considere pertinentes en caso de infracción a las políticas vigentes. Tales penalidades incluyen deducciones económicas, restricciones de uso y/o exclusión de los USUARIOS.</p>
        
              <h5>10.	CALIFICACIONES</h5>
              <p>10.1.	P-ARTICULAR establecerá un sistema de calificaciones y puntuación en relación a los USUARIOS que poseerá carácter público, sujeto a visualización por los demás USUARIOS.</p>
        
              <h5>11.	OBLIGACIONES</h5>
              <p>11.1.	En caso de contratación, el EMPLEADOR se compromete al estricto cumplimiento de sus obligaciones laborales, previsionales y fiscales consecuentes del negocio jurídico.</p>
        
              <h5>12.	RESPONSABILIDAD LIMITADA</h5>
              <p>12.1.	La intervención de P-ARTICULAR se extenderá desde la registración de los USUARIOS hasta la contratación de la TRABAJADORA. Al momento de concretarse la contratación cesará su intervención.</p>
              <p>12.2.	Cualquier actividad posterior a la contratación es responsabilidad exclusiva de los USUARIOS , eximiendo cualquier tipo de responsabilidad civil, laboral o penal de P-ARTICULAR.</p>
              
              <h5>13.	MODIFICACIÓN DE CONDICIONES</h5>
              <p>13.1.	P-ARTICULAR podrá modificar las condiciones de contratación sin previo consentimiento de los USUARIOS según las fluctuaciones que ocurran durante la vigencia del sistema. Tales modificaciones serán comunicadas periódicamente a los USUARIOS.</p>
        
              <h5>14.	ACEPTACIÓN</h5>
              <p>14.1.	Los USUARIOS aceptan las condiciones generales de contratación que no se encuentren expresamente previstas en éste título pero que surjan del uso normal y habitual del sistema.</p>
        
              <br>
              <p>Los DERECHOS SOBRE PROPIEDAD INTELECTUAL, MARCA, IMAGEN, ALGORITMOS y TODA EXPRESIÓN ABARCATIVA  DE LA IDEA CONCEPTUAL están RESERVADOS Y son de exclusiva titularidad de nuestra parte. Siendo ilegal y pasible de sanciones nacionales e internacionales el uso total o parcial de los mismos. Reservandonos asimismo la potestad de dar publicidad y difusión ilimitada al intento de fraude o fraude por parte de persona ajena a nuestra empresa.</p>
              <br><br><br>
            </div>
        
        </div>
    </div>
</div>

<!-- Conditions End -->

<app-footer></app-footer>




import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Category, SupplierCategory, Supplier } from '../../../interfaces/interfaces';
import { CategoriesService } from '../../../services/categories.service';
import { SuppliersService } from '../../../services/suppliers.service';
import { UsersService } from '../../../services/users.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmationComponent } from '../../helpers/modal-confirmation/modal-confirmation.component';

@Component({
  selector: 'app-supplier-categories',
  templateUrl: './supplier-categories.component.html',
  styleUrls: ['./supplier-categories.component.css']
})
export class SupplierCategoriesComponent implements OnInit {
  categories: Category[];
  supplierCategories: SupplierCategory[] = [];
  supplier: Supplier;
  isDesktop = false;
  isTablet = false;
  isMobile = false;

  constructor(private categoryService: CategoriesService,
              private supplierService: SuppliersService,
              private deviceService: DeviceDetectorService,
              private userService: UsersService,
              private router: Router,
              private ngModalService: NgbModal) { }

  ngOnInit(): void {
    this.isDesktop = this.deviceService.isDesktop();
    this.isTablet = this.deviceService.isTablet();
    this.isMobile = this.deviceService.isMobile();
    
    const userId = localStorage.getItem('userId');
    this.supplierService.getSupplierDetails(userId)
      .subscribe(res => {
        if (res['code'] === '200') {
          this.supplier = res['data'];
          this.getSupplierCategories();
        }
      })
  }

  getSupplierCategories(){
    this.supplierService.getSupplierCategories(this.supplier.id)
      .subscribe(res2=>{
        this.supplierCategories = res2['data'];
        this.getCategories();
      })
  }

  getCategories(){
    this.categoryService.getCategoriesByCountry().subscribe(res=>{
      console.log(res);
      if (res['code'] == '200') {
        this.categories = res['data'];
        if (this.categories.length !== 0) {
          if (this.supplierCategories.length > 0) {
            this.supplierCategories.forEach(supplCat=>{
              this.categories.forEach((cat,index)=>{
                if (cat.id === supplCat.category_id) {
                  this.categories.splice(index ,1);
                  // console.log('Eliminado:'+ cat.name);
                }
              })
            });
          }
        }
      }
    });
  }

  addCategory(cat: Category){
    // console.log(cat);
    let supplierCategory: SupplierCategory = {};

    supplierCategory = {
      supplier_id: this.supplier.id,
      category_id: cat.id,
      enabled: '1',
      experience: '',
      url: '',
      experience_time: '0',
      approved: '0'
    };
    this.supplierService.createSupplierCategory(supplierCategory)
      .then(res=>{
        // console.log(res);
        this.supplierService.getSupplierCategories(this.supplier.id)
          .subscribe(res2=>{
            // console.log(res2);
            this.supplierCategories = res2['data'];
            this.getCategories();
          })
      })
    
  }

  removeCategory(supplcat: SupplierCategory){
    this.supplierService.deleteSupplierCategory(supplcat.id)
      .then(res=>{
        this.supplierService.getSupplierCategories(this.supplier.id)
          .subscribe(res2=>{
            // console.log(res2);
            this.supplierCategories = res2['data'];
            this.getCategories();
          })
      })
  }

  finish(){
    const ref = this.ngModalService.open(ModalConfirmationComponent, {centered: true});
    ref.componentInstance.title = 'Felicitaciones!!!';
    // ref.componentInstance.elementType = 'Profesional';
    // ref.componentInstance.elementName = ' ' + supplier.names + ' '+ supplier.last_names;
    // ref.componentInstance.action = 'aprobar';
    ref.componentInstance.text1 = 'Gracias por completar tu información, en breve recibirás novedades.';
    ref.componentInstance.text2 = 'Presiona Cancelar si quieres seguir editando tu información, de otro modo presiona Acpetar para salir.';
    ref.result.then( ok => {
      this.userService.logout();
      this.router.navigate(['/home']);
    },
    dis => {
      console.log(dis);
    })
  }

}

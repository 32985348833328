import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilesService } from '../../../services/files.service';

@Component({
  selector: 'app-filepicker',
  templateUrl: './filepicker.component.html',
  styleUrls: ['./filepicker.component.css']
})
export class FilepickerComponent implements OnInit {
  @Output() fileSelected = new EventEmitter() ;
  @Input() folder: string;
  @Input() userId: string;

  path = '';
  mobileweb = false;
  textButton = 'Seleccionar archivo';

  constructor(private fileService: FilesService) { }

  ngOnInit(): void {
  }

  selectFile(event){
    const file = event.target.files[0];

    if (this.folder === 'courses') {
      this.fileSelected.emit(file);
    } else {
      const formData: FormData = new FormData();
      const fileName: string = file.name.replace(/\s/g, '_');
      formData.append('file', file , fileName);
      formData.append('path', this.path);
      this.fileService.uploadFile(formData)
        .then( resp => {
          console.log(resp);
          this.fileSelected.emit(file);
        });
    }

  }
}

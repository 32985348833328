import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../../services/users.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  alertUser = false;
  alertPassword = false;
  email = '';
  pass = '';
  alertLogin = false;
  link = '';

  constructor(public modal: NgbActiveModal,
              private userService: UsersService,
              private router: Router) { }

  ngOnInit(): void {
  }

  login(){
    this.alertLogin = false;
    this.userService.login(this.email, this.pass,'','','','PROFESIONAL', '','')
      .then(res=>{
        console.log(res);
        if (!res) {
          this.alertLogin = true;
        } else {
          //window.location.reload();
          this.modal.close()
          if (this.link !== '') {
            this.router.navigate(['/'+this.link]);
          } else {
            this.router.navigate(['/supplier-sidemenu']);
          }
          
        }
       
      })
  }

  forgotPass(){
    this.modal.close();
    this.router.navigate(['/forgot-password']);
  }

}

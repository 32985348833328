import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../../services/users.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  email = '';
  currentPass = '';
  newPass = '';
  newPassConfirm = '';
  alertUser = false;
  alertOK = false;
  alertError = false;
  alertCurrentPassword = false;
  alertNewPassword = false;
  alertNewPasswordConfirm = false;
  errorMessage = '';

  constructor(private userService: UsersService) {}

  ngOnInit(): void {
  }

  process(){
    if (this.validate()) {
      this.userService.changepass(this.newPass)
      .then(res=>{
        if (res['code'] === '200') {
          this.alertOK = true;
        } else {
          this.errorMessage = res['msg'];
          this.alertError = true;
        }
      })
    }
  }

  validate(){
    return true;
  }

}

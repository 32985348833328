<app-header></app-header>

    <!-- Spinner Start -->
    <!-- <div
      id="spinner"
      class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
    >
      <div class="spinner-grow text-primary" role="status"></div>
    </div> -->
    <!-- Spinner End -->
    
    <!-- Carousel Start -->
    <div class="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
      <div id="header-carousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="w-100" src="/assets/img/1900x1080-Limpieza.jpg" alt="Image" />
            <div class="carousel-caption">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-12 col-lg-10">
                    <h5
                      class="text-light text-uppercase mb-3 animated slideInDown">
                        Bienvenido a P-ARTICULAR
                    </h5>
                    <h1 class="display-2 text-light mb-3 animated slideInDown">
                      Anotáte aquí para trabajar en casas de familia. 
                    </h1>
                    <ol class="breadcrumb mb-4 pb-2">
                      <li class="breadcrumb-item fs-5 text-light">
                        Comercial
                      </li>
                      <li class="breadcrumb-item fs-5 text-light">
                        Residencial
                      </li>
                    </ol>
                    <!-- <a href="" class="btn btn-primary py-3 px-5"
                      >Pedir cotización</a> -->
                    <!-- <div class="form-floating">
                      <input
                        type="text"
                        class="form-control bg-dark border-0"
                        id="search"
                        placeholder="En que te podemos ayudar"/>
                      <label for="gname">En que te podemos ayudar?</label>
                    </div> -->
                    <!-- Button trigger modal -->
                    <button type="button" class="btn btn-primary"(click)="quotation()">
                      Buscar personal doméstico
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img class="w-100" src="/assets/img/1900x1080-Cocinera.jpg" alt="Image" />  
            <div class="carousel-caption">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-12 col-lg-10">
                    <h5
                      class="text-light text-uppercase mb-3 animated slideInDown">
                      Bienvenido a P-ARTICULAR
                    </h5>
                    <h1 class="display-2 text-light mb-3 animated slideInDown">
                      Aseo, lavado y planchado, cuidado de personas, cocineros/as y más...
                    </h1>
                    <ol class="breadcrumb mb-4 pb-2">
                      <li class="breadcrumb-item fs-5 text-light">
                        Comercial
                      </li>
                      <li class="breadcrumb-item fs-5 text-light">
                        Residencial
                      </li>
                    </ol>
                    <!-- <a href="" class="btn btn-primary py-3 px-5"
                      >Mas detalles</a> -->
                      <!-- <div class="form-floating">
                        <input
                          type="text"
                          class="form-control bg-dark border-0"
                          id="search"
                          placeholder="En que te podemos ayudar"/>
                        <label for="gname">En que te podemos ayudar?</label>
                      </div> -->
                      <button type="button" class="btn btn-primary"(click)="quotation()">
                        Buscar personal doméstico
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Anterior</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Proximo</span>
        </button>
      </div>
    </div>
    <!-- Carousel End -->

    <!-- About Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <div class="row g-5">
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <div
              class="position-relative overflow-hidden ps-5 pt-5 h-100"
              style="min-height: 400px"
            >
              <img
                class="position-absolute w-100 h-100"
                src="/assets/img/img-707x700-Limpieza_thumb_up.jpg"
                alt=""
                style="object-fit: cover"
              />
              <div
                class="position-absolute top-0 start-0 bg-white pe-3 pb-3"
                style="width: 200px; height: 200px; "
              >
                <div
                  class="d-flex flex-column justify-content-center text-center bg-primary h-100 p-3"
                  style="background-color: var(--primary);"
                >
                  <h1 class="text-white">200+</h1>  
                  <h3 class="text-white">Personas registradas</h3>
                  <h5 class="text-white mb-0">Limpieza y Cocina</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="h-100">
              <div class="border-start border-5 border-primary ps-4 mb-5">
                <h6 class="text-body text-uppercase mb-2">Te ofrecemos</h6>
                <h1 class="display-6 mb-0">
                  Directorio verificado de personas registradas !
                </h1>
              </div>
              <p>
                Somos una empresa del grupo Particulares, con la misión de acercarte al mejor personal de limpieza , cuidado de personas y servicios de cocina.
              </p>
              <p class="mb-4">
                Con el uso de esta tecnología ponemos en contacto a los empleadores con los trabajadores.
              </p>
              <p class="mb-4">
                En tiempos de pandemia hemos aprendido que hoy más que nunca la colaboración on-line es fundamental.
              </p>
              <div class="alert alert-success" role="alert">
                Si sos una persona que sabe trabajar en limpieza , cuidado de personas o
                tareas de la cocina, <a style="color: var(--tertiary);" href="">registráte aquí</a> 
                y aparecerás en las búsquedas de nuestros
                clientes quienes te podrán contactar directamente para requerir tus servicios.
              </div>
              <div class="border-top mt-4 pt-4">
                <div class="row g-4">
                  <div class="col-sm-4 d-flex wow fadeIn" data-wow-delay="0.1s" >
                    <i 
                      class="fa fa-check fa-2x text-primary flex-shrink-0 me-3"
                    ></i>
                    <h6 class="mb-0">Puntualidad</h6>
                  </div>
                  <div class="col-sm-4 d-flex wow fadeIn" data-wow-delay="0.3s">
                    <i
                      class="fa fa-check fa-2x text-primary flex-shrink-0 me-3"
                    ></i>
                    <h6 class="mb-0">Calidad</h6>
                  </div>
                  <div class="col-sm-4 d-flex wow fadeIn" data-wow-delay="0.5s">
                    <i
                      class="fa fa-check fa-2x text-primary flex-shrink-0 me-3"
                    ></i>
                    <h6 class="mb-0">Profesionales verificados</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- About End -->

    <!-- Facts Start -->
    <div class="container-fluid my-5 p-0">
      <div class="row g-0">
        <div class="col-xl-3 col-sm-6 wow fadeIn" data-wow-delay="0.1s">
          <div class="position-relative">
            <img class="img-fluid w-100" src="/assets/img/fact-1.jpg" alt="" />
            <div class="facts-overlay">
              <h1 class="display-1">01</h1>
              <h4 class="text-white mb-3">Limpieza general</h4>
              <p class="text-white">
                Limpieza general. Limpieza profunda por mudanza. Lavado y planchado de ropa.
              </p>
              <a class="text-white small" href=""
                >LEER MAS<i class="fa fa-arrow-right ms-3"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 wow fadeIn" data-wow-delay="0.3s">
          <div class="position-relative">
            <img class="img-fluid w-100" src="/assets/img/fact-2.jpg" alt="" />
            <div class="facts-overlay">
              <h1 class="display-1">02</h1>
              <h4 class="text-white mb-3">Cuidado de Niños</h4>
              <p class="text-white">
                Cuidado de niños y dolescentes. Cuidados especiales. Rangos por edades.
              </p>
              <a class="text-white small" href=""
                >LEER MAS<i class="fa fa-arrow-right ms-3"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 wow fadeIn" data-wow-delay="0.5s">
          <div class="position-relative">
            <img class="img-fluid w-100" src="/assets/img/fact-3.jpg" alt="" />
            <div class="facts-overlay">
              <h1 class="display-1">03</h1>
              <h4 class="text-white mb-3">Cuidado de Adultos</h4>
              <p class="text-white">
                Cuidado de adultos mayores. Cuidados especiales. Aseo corporal. Transportación de adultos mayores. 
              </p>
              <a class="text-white small" href=""
                >LEER MAS<i class="fa fa-arrow-right ms-3"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 wow fadeIn" data-wow-delay="0.7s">
          <div class="position-relative">
            <img class="img-fluid w-100" src="/assets/img/fact-4.jpg" alt="" />
            <div class="facts-overlay">
              <h1 class="display-1">04</h1>
              <h4 class="text-white mb-3">Cocina</h4>
              <p class="text-white">
                Preparación de comidas en general. Platos especiales. Comidas para eventos.
              </p>
              <a class="text-white small" href=""
                >LEER MAS<i class="fa fa-arrow-right ms-3"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Facts End -->

    <!-- Features Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <div class="row g-5">
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="border-start border-5 border-primary ps-4 mb-5">
              <h6 class="text-body text-uppercase mb-2">Por qué elegirnos!</h6>
              <h1 class="display-6 mb-0">
                Te brindamos soluciones
              </h1>
            </div>
            <p class="mb-5">
              Particulares te acerca una amplia variedad de personas dispuestas a ofrecerte su tiempo y experiencia para resolver las necesidades del hogar. A través de una plataforma sencilla pero sólida, te ponemos en contacto directo con los trabajadores sin que tengas que estar solicitando ayuda a amigos o conocidos. Con un simple click, nos cuentas que necesitas y buscamos en nuestra base de datos los trabajadores que podrán ayudarte.
            </p>
            <div class="row gy-5 gx-4">
              <div class="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                <div class="d-flex align-items-center mb-3">
                  <i
                    class="fa fa-check fa-2x text-primary flex-shrink-0 me-3"
                  ></i>
                  <h6 class="mb-0">Gran número de trabajadores</h6>
                </div>
                <span
                  >Trabajadores de todas las especialidades relacionadas a tu hogar están inscriptos en nuestra plataforma y listos para ayudarte.</span
                >
              </div>
              <div class="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                <div class="d-flex align-items-center mb-3">
                  <i
                    class="fa fa-check fa-2x text-primary flex-shrink-0 me-3"
                  ></i>
                  <h6 class="mb-0">Contamos con experiencia en el mercado.</h6>
                </div>
                <span
                  >Nuestros trabajadores también cuentan con amplia experiencia.</span
                >
              </div>
              <div class="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                <div class="d-flex align-items-center mb-3">
                  <i
                    class="fa fa-check fa-2x text-primary flex-shrink-0 me-3"
                  ></i>
                  <h6 class="mb-0">Gran número de clientes satisfechos.</h6>
                </div>
                <span
                  >Muchos clientes como vos encontraron en P-ARTICULAR la ayuda necesaria para llevar adelante sus proyectos.</span
                >
              </div>
              <div class="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                <div class="d-flex align-items-center mb-3">
                  <i
                    class="fa fa-check fa-2x text-primary flex-shrink-0 me-3"
                  ></i>
                  <h6 class="mb-0">Precio y calidad acordes al servicio.</h6>
                </div>
                <span
                  >Si bien el precio es acordado entre tú y el profesional, cuidamos que estén dentro de los estandares del mercado.</span
                >
              </div>
            </div>
          </div>
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
            <div
              class="position-relative overflow-hidden ps-5 pt-5 h-100"
              style="min-height: 400px"
            >
              <img
                class="position-absolute w-100 h-100"
                src="/assets/img/feature.jpg"
                alt=""
                style="object-fit: cover"
              />
              <!-- <div
                class="position-absolute top-0 start-0 bg-white pe-3 pb-3"
                style="width: 200px; height: 200px"
              >
                <div
                  class="d-flex flex-column justify-content-center text-center bg-primary h-100 p-3"
                >
                  <h1 class="text-white">8</h1>
                  <h2 class="text-white">Años</h2>
                  <h5 class="text-white mb-0">Experiencia en el mercado.</h5>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Features End -->

    <!-- Service Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <div class="row g-5 align-items-end mb-5">
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="border-start border-5 border-primary ps-4">
              <h6 class="text-body text-uppercase mb-2">Especialidades</h6>
              <h1 class="display-6 mb-0">
                Cubrimos diversas áreas relacionadas a servicios domésticos para tu hogar, cuidado de personas y cocina.
              </h1>
            </div>
          </div>
          <div class="col-lg-6 text-lg-end wow fadeInUp" data-wow-delay="0.3s">
            <a class="btn btn-primary py-3 px-5" href="">Mas especialidades</a>
          </div>
        </div>
        <div class="row g-4 justify-content-center">
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="service-item bg-light overflow-hidden h-100">
              <img class="img-fluid" src="/assets/img/service-1.jpg" alt="" />
              <div class="service-text position-relative text-center h-100 p-4">
                <h5 class="mb-3">Limpieza general</h5>
                <p>
                  Una casa limpia ofrece un aspecto impecable. Si te da pereza la limpieza general de la casa, piensa que tu familia y tú son los que más disfrutarán del resultado. Es el momento de que recurras a un profesional de la limpieza.
                </p>
                <a class="small" href=""
                  >LERR MAS<i class="fa fa-arrow-right ms-3"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="service-item bg-light overflow-hidden h-100">
              <img class="img-fluid" src="/assets/img/service-2.jpg" alt="" />
              <div class="service-text position-relative text-center h-100 p-4">
                <h5 class="mb-3">Limpieza profunda</h5>
                <p>
                  Las casas pasan por mucho desgaste a lo largo de los años, y de vez en cuando, necesitan una limpieza profunda a fondo. Esto es especialmente importante si está en el proceso de venta de una casa, antes de tomar fotos profesionales para el listado de su casa, mientras se prepara para las visitas de sus posibles compradores, o cuando se esté preparando para entregar las llaves.
                </p>
                <a class="small" href=""
                  >LEER MAS<i class="fa fa-arrow-right ms-3"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="service-item bg-light overflow-hidden h-100">
              <img class="img-fluid" src="/assets/img/service-3.jpg" alt="" />
              <div class="service-text position-relative text-center h-100 p-4">
                <h5 class="mb-3">Cocina</h5>
                <p>
                  Después de una larga jornada de trabajo, lo último que nos apetece al llegar a casa es ponernos a cocinar para el día siguiente. Sin embargo, frente a la desventaja de tener que dedicar tiempo a la cocina para poder comer preparaciones caseras en tu casa, en Particulares te acercamos a los profesionales que te ayudarán a cuidar la dieta, estar en buena forma y como no, cuidar el bolsillo.
                </p>
                <a class="small" href=""
                  >LEER MAS<i class="fa fa-arrow-right ms-3"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="service-item bg-light overflow-hidden h-100">
              <img class="img-fluid" src="/assets/img/service-4.jpg" alt="" />
              <div class="service-text position-relative text-center h-100 p-4">
                <h5 class="mb-3">Cuidado de niños pequeños</h5>
                <p>
                  De manera cada vez más frecuente, el cuidado de primera infancia desempeña un papel esencial en el desarrollo del niño, y ofrece un valioso apoyo a las familias con niños pequeños. Por lo tanto, es importante comprender el impacto de estos servicios y asegurar su calidad y su accesibilidad.
                </p>
                <a class="small" href=""
                  >LEER MAS<i class="fa fa-arrow-right ms-3"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="service-item bg-light overflow-hidden h-100">
              <img class="img-fluid" src="/assets/img/service-5.jpg" alt="" />
              <div class="service-text position-relative text-center h-100 p-4">
                <h5 class="mb-3">Cuidado de niños mayores</h5>
                <p>
                  Durante la segunda infancia, los niños necesitan supervisión. Debe haber un adulto responsable disponible para prepararlos y enviarlos a la escuela en la mañana y vigilarlos después de la escuela hasta que usted regrese a casa del trabajo. Incluso los niños que se acercan a la adolescencia, los niños de once y doce años, no deben llegar a una casa vacía por la tarde a menos que demuestren una madurez inusual para su edad.
                </p>
                <a class="small" href=""
                  >LEER MAS<i class="fa fa-arrow-right ms-3"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="service-item bg-light overflow-hidden h-100">
              <img class="img-fluid" src="/assets/img/service-6.jpg" alt="" />
              <div class="service-text position-relative text-center h-100 p-4">
                <h5 class="mb-3">Cuidado de adultos mayores</h5>
                <p>
                  El cuidado del adulto mayor incluye mantener limpia su vestimenta, ropa de cama, artículos de baño y entorno. Esto los hará sentirse cómodos y estimados. Además, pueden sufrir de incontinencia u otras patologías, por lo cual habrá que estar atentos para hacer la higiene correspondiente.
                </p>
                <p>
                  Fuente: <a href="https://www.cetys.mx/trends/ciencia/como-garantizar-el-cuidado-del-adulto-mayor-en-mexico" target="_blank">Cetys</a>
                </p>
                <a class="small" href=""
                  >LEER MAS<i class="fa fa-arrow-right ms-3"></i
                ></a>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <!-- Service End -->

    <!-- Appointment Start -->
    <!-- <div
      class="container-fluid appointment my-5 py-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div class="container py-5">
        <div class="row g-5">
          <div class="col-lg-5 col-md-6 wow fadeIn" data-wow-delay="0.3s">
            <div class="border-start border-5 border-primary ps-4 mb-5">
              <h6 class="text-white text-uppercase mb-2">Pide un servicio</h6>
              <h1 class="display-6 text-white mb-0">
                Con una empresa o profesional independiente.
              </h1>
            </div>
            <p class="text-white mb-0">
              Coloca tu pedido y uno o mas profesionales se pondrán en contacto contigo para darte su cotización y acordar el dia y hora para comenzar tu proyecto o servicio. 
            </p>
          </div>
          <div class="col-lg-7 col-md-6 wow fadeIn" data-wow-delay="0.5s">
            <form>
              <div class="row g-3">
                <div class="col-sm-6">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control bg-dark border-0"
                      id="gname"
                      placeholder="Gurdian Name"
                    />
                    <label for="gname">Tu nombre</label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-floating">
                    <input
                      type="email"
                      class="form-control bg-dark border-0"
                      id="gmail"
                      placeholder="Gurdian Email"
                    />
                    <label for="gmail">Tu email</label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control bg-dark border-0"
                      id="cname"
                      placeholder="Child Name"
                    />
                    <label for="cname">Tu celular</label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control bg-dark border-0"
                      id="cage"
                      placeholder="Child Age"
                    />
                    <label for="cage">Tipo de servicio</label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-floating">
                    <textarea
                      class="form-control bg-dark border-0"
                      placeholder="Leave a message here"
                      id="message"
                      style="height: 100px"
                    ></textarea>
                    <label for="message">Mensaje</label>
                  </div>
                </div>
                <div class="col-12">
                  <button class="btn btn-primary w-100 py-3" type="submit">
                    Envía tu solicitud
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Appointment End -->

    <!-- Team Start -->
    <!-- <div class="container-xxl py-5">
      <div class="container">
        <div class="row g-5 align-items-end mb-5">
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="border-start border-5 border-primary ps-4">
              <h6 class="text-body text-uppercase mb-2">El equipo</h6>
              <h1 class="display-6 mb-0">Nuestros expertos</h1>
            </div>
          </div>
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
            <p class="mb-0">
              Profesionales que destacan en este momento por su actividad y buenas calificaciones.
            </p>
          </div>
        </div>
        <div class="row g-4">
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="team-item position-relative">
              <img class="img-fluid" src="/assets/img/person-1.jpg" alt="" />
              <div class="team-text bg-white p-4">
                <h5>Gloria Gomez</h5>
                <span>Aseos Generales</span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="team-item position-relative">
              <img class="img-fluid" src="/assets/img/person-3.jpg" alt="" />
              <div class="team-text bg-white p-4">
                <h5>Claudia Sosa</h5>
                <span>Cocinera</span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="team-item position-relative">
              <img class="img-fluid" src="/assets/img/person-2.jpg" alt="" />
              <div class="team-text bg-white p-4">
                <h5>Marcela Tapia</h5>
                <span>Cuidadora de Mayores</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Team End -->

    <!-- Testimonial Start -->
    <!-- <div class="container-xxl py-5">
      <div class="container">
        <div class="row g-5">
          <div class="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
            <div class="border-start border-5 border-primary ps-4 mb-5">
              <h6 class="text-body text-uppercase mb-2">Testimoniales</h6>
              <h1 class="display-6 mb-0">Lo que dicen nuestros clientes satisfechos!</h1>
            </div>
            <p class="mb-4">
              Muchos clientes ya han experimentado la satisfacción de ver sus proyectos o remodelaciones concretadas con nosotros.
            </p>
            <div class="row g-4">
              <div class="col-sm-6">
                <div class="d-flex align-items-center mb-2">
                  <i class="fa fa-users fa-2x text-primary flex-shrink-0"></i>
                  <h1 class="ms-3 mb-0">123+</h1>
                </div>
                <h5 class="mb-0">Clientes satisfechos</h5>
              </div>
              <div class="col-sm-6">
                <div class="d-flex align-items-center mb-2">
                  <i class="fa fa-check fa-2x text-primary flex-shrink-0"></i>
                  <h1 class="ms-3 mb-0">123+</h1>
                </div>
                <h5 class="mb-0">Proyectos realizados</h5>
              </div>
            </div>
          </div>
          <div class="col-lg-7 wow fadeInUp" data-wow-delay="0.5s">
            <div class="owl-carousel testimonial-carousel">
              <div class="testimonial-item">
                <img
                  class="img-fluid mb-4"
                  src="/assets/img/testimonial-1.jpg"
                  alt=""
                />
                <p class="fs-5">
                  Dolores sed duo clita tempor justo dolor et stet lorem kasd
                  labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy
                  et labore et tempor diam tempor erat.
                </p>
                <div
                  class="bg-primary mb-3"
                  style="width: 60px; height: 5px"
                ></div>
                <h5>Client Name</h5>
                <span>Profession</span>
              </div>
              <div class="testimonial-item">
                <img
                  class="img-fluid mb-4"
                  src="/assets/img/testimonial-2.jpg"
                  alt=""
                />
                <p class="fs-5">
                  Dolores sed duo clita tempor justo dolor et stet lorem kasd
                  labore dolore lorem ipsum. At lorem lorem magna ut et, nonumy
                  et labore et tempor diam tempor erat.
                </p>
                <div
                  class="bg-primary mb-3"
                  style="width: 60px; height: 5px"
                ></div>
                <h5>Client Name</h5>
                <span>Profession</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Testimonial End -->

    <app-footer></app-footer>

      <div class="modal-header">
        <h4 class="modal-title center">{{ modalTitle }}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>

      <div class="modal-body row">
        <!-- Category -->
        <div *ngIf="questions[0].active">
          <h6>{{ questions[0].question }}</h6>
          <div class="form-floating" *ngIf="questions[0].id === 1">
              <input
                  type="text"
                  class="form-control bg-light border-0"
                  placeholder="Especialidad"
                  (keyup)="search($event)"/>
                  
              <label for="gname">Especialidad</label>
          </div> 
          
          <div class="selector" *ngFor="let cat of showCategories" (click)="selection1(cat)">
            <p><b>{{ cat.category_name }}:</b> {{cat.subcategories_description}}</p>
          </div>
        </div>
        <!-- ZIP Code -->
        <div *ngIf="questions[1].active">
          <h6>{{ questions[1].question }}</h6>
          <div class="form-floating" *ngIf="questions[0].id === 1">
              <input 
                  name="zip"
                  type="text"
                  class="form-control bg-light border-0"
                  placeholder="Código postal"
                  [(ngModel)] = "zipCode"
                  (ngModelChange)="checkZip($event)"/>
                  
              <label for="gname">Código postal</label>
              <br>
              <div *ngIf="sepomex"> 
                <h5> {{ zipResult[0].estado }}</h5>
                <div class="selector" *ngFor="let loc of zipResult">
                  <p (click)="selection2(loc)" > {{ loc.ciudad }} </p>
                </div>
              </div>
          </div> 
        </div>
        <!-- Urgency -->
        <div *ngIf="questions[2].active">
          <h6>{{ questions[2].question }}</h6>
          <div class="form-floating" *ngIf="questions[0].id === 1">
              <br>
              <div class="selector" *ngFor="let urgency of ['Me urge','Puedo esperar']">
                <p (click)="selection3(urgency)" > {{ urgency }} </p>
              </div>
          </div> 
        </div>
        <!-- Quotations qty -->
        <div *ngIf="questions[3].active">
          <h6>{{ questions[3].question }}</h6>
          <div class="form-floating" *ngIf="questions[0].id === 1">
              <br>
              <div class="selector" *ngFor="let quotations of ['Una','Dos','Tres']">
                <p (click)="selection4(quotations)" > {{ quotations }} </p>
              </div>
          </div> 
        </div>
        <!-- Request details -->
        <div *ngIf="questions[4].active">
          <h6>{{ questions[4].question }}</h6>
          <div class="form-floating" *ngIf="questions[0].id === 1">
              <br>
              <div class="alert alert-danger" role="alert" *ngIf="alertMessage">
                Debes colocar una descripción
              </div>
              <div class="form-floating">
                <textarea
                  name="message"
                  [(ngModel)]="customerMessage"
                  class="form-control border-0 bg-light"
                  placeholder="Leave a message here"
                  id="message"
                  style="height: 150px"></textarea>
                <label for="message">Mensaje</label>
            </div> 
            <!-- Image  -->
            <div class="container mt-3">
              <div class="alert alert-danger" role="alert" *ngIf="alertImageSize">
                El archivo sebe ser menor que 2MB
              </div>
              <div class="row">
                <div class="col-sm img-wraps" *ngFor="let img of selectedFiles">
                  <span class="closes" title="Quitar" (click)="removeImage(img)">×</span>
                  <img [src]="img | imageBlob" alt="Sin imagen" width="100" height="100" class="img-thumbnail">
                </div>
              </div>
            </div>            
            <button type="button" (click)="fileInput.click()" class="btn btn-outline-primary mt-5">
              <span>Subir archivos (opcional)</span>
              <input #fileInput type="file" (change)="selectFile($event)" style="display:none;" />
            </button>
          </div>
        </div>
        <!-- Customer information -->
        <div *ngIf="questions[5].active">
          <h6>{{ questions[5].question }}</h6>
          <div class="row g-3">
            <div class="col-md-12">
              <div class="alert alert-danger" role="alert" *ngIf="alertName">
                Debes indicar tu nombre
              </div>
              <div class="form-floating">
                <input [disabled]="inputDisabled"
                  name="custName"
                  [(ngModel)]="customerName"
                  type="text"
                  class="form-control border-0 bg-light"
                  id="name"
                  placeholder="Nombre"
                />
                <label for="name">Nombre</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="alert alert-danger" role="alert" *ngIf="alertEmail">
                Debes indicar tu email
              </div>
              <div class="form-floating">
                <input [disabled]="inputDisabled"
                  name="custEmail"
                  [(ngModel)]="customerEmail"
                  type="email"
                  class="form-control border-0 bg-light"
                  id="email"
                  placeholder="Email"
                />
                <label for="email">Email</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="alert alert-danger" role="alert" *ngIf="alertPhone">
                Debes indicar tu teléfono
              </div>
              <div class="form-floating">
                <input [disabled]="inputDisabled"
                  name="custPhone"
                  [(ngModel)]="customerPhone"
                  type="text"
                  class="form-control border-0 bg-light"
                  id="email"
                  placeholder="Teléfono"
                />
                <label for="email">Teléfono</label>
              </div>
            </div>
          </div>
          <!-- Legal terms -->
          <div class="row g-3 mt-3">
            <div class="col-md-12">         
              <div class="alert alert-danger" role="alert" *ngIf="alertTerms">
                Debes aceptar Términos y Condiciones
              </div>
              <div class="form-check mt-2">
                <input [disabled]="inputDisabled"
                    name="custTerms"
                    [(ngModel)]="customerLegalTermsAccepted"
                    class="form-check-input" 
                    type="checkbox" 
                    value="" 
                    id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                    He leido y acepto la <a target="_blank" routerLink="/privacy-statement">Política de privacidad</a> y los <a target="_blank" routerLink="/legal-terms">Téminos y condiciones</a> 
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- Request OK -->
        <!-- Quotations qty -->
        <div *ngIf="requestOK">
          <div class="alert alert-success" role="alert">
            Solicitud finalizada satisfactoriamente. 
          </div>
          <p>Gracias por utilizar nuestra plataforma. Tu búsqueda está configurada. Presiona Ver Resultado para continuar.</p>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-tertiary" (click)="modal.close('Close click')" *ngIf="!requestOK">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="back()" *ngIf="currentQuestion !== 0 && !requestOK"><i class="bi bi-caret-left"></i> Anterior</button>
        <button type="button" class="btn btn-primary" (click)="next()" *ngIf="currentQuestion !== 0 && !questions[this.currentQuestion].last && !this.requestOK">Siguiente <i class="bi bi-caret-right"></i></button>
        <button class="btn btn-primary py-3 px-5" type="submit" (click)="publish()" *ngIf="questions[this.currentQuestion].last && !requestOK">
          Finalizar
        </button>
        <button class="btn btn-primary py-3 px-5" type="submit" (click)="viewResults()" *ngIf="questions[this.currentQuestion].last && requestOK">
          Ver resultado
        </button>
      </div>
    

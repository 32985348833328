import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment.prod';

const URL2 = environment.url2;

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  constructor(private http: HttpClient,
              private route: Router) { }

  checkConnection2() {
    const headers = {};
    const api = URL2 + 'api/verifyConnection';

    return new Promise( resolve => {
      this.http.get(api)
        .subscribe( async resp => {
          // console.log(resp);
          resolve(resp);
        } , error => {
          // this.route.navigateRoot('connection-error');
          this.route.navigate(['connection-error']);
        });
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.css']
})
export class ModalConfirmationComponent implements OnInit {
  title: string;
  // elementType: string;
  // elementName: string;
  // action = 'eliminar';
  text1 = 'Toda la información relacionada será permanentemente eliminada.';
  text2 = 'Esta operación no puede revertirse.';

  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {
  }

}

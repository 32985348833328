<app-header-user></app-header-user>
<div class="container-fluid">
    <div class="row flex-nowrap">
        <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-light">
            <div class="d-flex flex-column align-items-center align-items-sm-start px-0 pt-2 text-white min-vh-100">
                <a href="/" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <span class="fs-5 d-none d-sm-inline text-dark">Menu</span>
                </a>
                <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                    <li class="nav-item" *ngFor="let opt of menuOptions">
                        <div *ngIf="opt.active">
                            <a (click)="menuSelect(opt.id)" class="nav-link {{opt.activeClass}} align-middle pointer w-100">
                                <i class="fs-4 {{opt.iconClass}}"></i> <span class="ms-1 d-none d-sm-inline text-tertiary">{{opt.name}}</span>
                            </a>
                        </div>
                    </li>
                    
                    <!-- <li class="nav-item">
                        <a (click)="menuSelect(2)" data-bs-toggle="collapse" class="nav-link {{active2}} px-0 align-middle pointer">
                            <i class="fs-4 bi-speedometer2"></i> <span class="ms-1 d-none d-sm-inline">Dashboard</span> </a>
                    </li>
                    <li>
                        <a (click)="menuSelect(3)" class="nav-link px-0 align-middle pointer">
                            <i class="fs-4 bi-table"></i> <span class="ms-1 d-none d-sm-inline">Orders</span></a>
                    </li>
                    <li>
                        <a (click)="menuSelect(4)" data-bs-toggle="collapse" class="nav-link px-0 align-middle pointer">
                            <i class="fs-4 bi-bootstrap"></i> <span class="ms-1 d-none d-sm-inline">Bootstrap</span></a>
                    </li>
                    <li>
                        <a href="#submenu3" data-bs-toggle="collapse" class="nav-link px-0 align-middle">
                            <i class="fs-4 bi-grid"></i> <span class="ms-1 d-none d-sm-inline">Products</span> </a>
                    </li>
                    <li>
                        <a href="#" class="nav-link px-0 align-middle">
                            <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline">Customers</span> </a>
                    </li> -->
                </ul>
                <hr>
                <div class="dropdown pb-4">
                    <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="https://github.com/mdo.png" alt="hugenerd" width="30" height="30" class="rounded-circle">
                        <span class="d-none d-sm-inline mx-1">loser</span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
                        <!-- <li><a class="dropdown-item" href="#">New project...</a></li>
                        <li><a class="dropdown-item" href="#">Settings</a></li>
                        <li><a class="dropdown-item" href="#">Profile</a></li> -->
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item pointer" (click)="logout()">Sign out</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col py-1">
            <app-supplier-home *ngIf="menu===1"></app-supplier-home>
            <app-supplier-profile *ngIf="menu===2"></app-supplier-profile>
            <app-supplier-certificates *ngIf="menu===3"></app-supplier-certificates>
            <app-supplier-purchases *ngIf="menu===4"></app-supplier-purchases>
            <app-supplier-suscriptions *ngIf="menu===5"></app-supplier-suscriptions>
        </div>
    </div>
</div>

<app-footer></app-footer>

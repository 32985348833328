import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ServicesComponent } from './components/ourservices/services.component';
import { ContactComponent } from './components/contact/contact.component';
import { ConnectionErrorComponent } from './components/helpers/connection-error/connection-error.component';
import { LoginComponent } from './components/auth/login/login.component';
import { LegalTermsComponent } from './components/legal/legal-terms/legal-terms.component';
import { PrivacyStatementComponent } from './components/legal/privacy-statement/privacy-statement.component';
import { SupplierRegisterComponent } from './components/suppliers/supplier-register/supplier-register.component';
import { SupplierSidemenuComponent } from './components/suppliers/supplier-sidemenu/supplier-sidemenu.component';
import { LoginFullComponent } from './components/auth/login-full/login-full.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';

const routes: Routes = [
  {path:  "", pathMatch:  "full",redirectTo:  "home"},
  {path:'home', component: HomeComponent},
  {path:'about', component: AboutComponent},
  {path:'services', component: ServicesComponent},
  // {path:'contact', component: ContactComponent},
  {path:'connection-error', component: ConnectionErrorComponent},
  {path:'login', component: LoginComponent},
  {path:'legal-terms', component: LegalTermsComponent},
  {path:'privacy-statement', component: PrivacyStatementComponent},
  {path:'inscripcion', component: SupplierRegisterComponent},
  {path:'supplier-register', component: SupplierRegisterComponent},
  {path:'supplier-sidemenu', component: SupplierSidemenuComponent},
  {path:'login-full', component: LoginFullComponent},
  {path:'forgot-password', component: ForgotPasswordComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<!-- Supplier Profile Start -->
<h1 class="text-center text-tertiary">Mi Perfil</h1>
<!-- <div class="container-xxl py-5"> -->
  <!-- <div class="container"> -->
    <ul class="nav nav-tabs">
      <li class="nav-item " *ngFor="let item of menuItems">
        <div *ngIf="item.active">
          <a (click)="selectItem(item.id)" class="nav-link pointer {{ item.activeClass }} " [aria-current]="item.ariaCurrent" >{{item.name}}</a>
        </div>
      </li>
    </ul>

    <div class="mt-2">
      <app-supplier-personal-data *ngIf="menu===1"></app-supplier-personal-data>
      <app-supplier-categories *ngIf="menu===2"></app-supplier-categories>
    </div>

  <!-- </div> -->
<!-- </div> -->

<!-- Supplier Profile End -->



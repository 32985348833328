import { Component, ElementRef, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CategoriesService } from '../../../services/categories.service';
import { Category, SubcatWithCat, Usuario, Post } from '../../../interfaces/interfaces';
import { StatescitiesService } from '../../../services/statescities.service';
import { FilesService } from '../../../services/files.service';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { UsersService } from '../../../services/users.service';
import { PostsService } from '../../../services/posts.service';

@Component({
  selector: 'app-ask-quotation',
  templateUrl: './ask-quotation.component.html',
  styleUrls: ['./ask-quotation.component.css']
})
export class AskQuotationComponent implements OnInit {
  // @ViewChild('nombre', { static: false }) searchString: ElementRef ;
  modalTitle: string = 'Configurando tu búsqueda';
  selectedText: string;
  questions = [
    {id:1, question: 'Que especialidad buscas?', answer: '', optional: 0, active: true, last: false, enabled: true},
    {id:2, question: 'Donde estás ubicado?', answer: '', optional: 0, active: false, last: false, enabled: true},
    {id:3, question: 'Urgencia?', answer: '', optional: 0, active: false, last: false, enabled: true},
    {id:4, question: 'Cuantas cotizaciones quisieras recibir como máximo?', answer: '', optional: 0, active: false, last: false, enabled:false},
    {id:5, question: 'Danos una desripción del trabajo que necesitas.', answer: '', optional: 0, active: false, last: false, enabled: true},
    {id:6, question: 'Dejanos tus datos.', answer: '', optional: 0, active: false, last: true, enabled: true}
  ];
  currentQuestion = 0;
  categories: SubcatWithCat[];
  showCategories: SubcatWithCat[] = [];
  zipCode: string;
  zipResult: any;
  sepomex = false;
  imageURL:SafeUrl;
  customerMessage = '';
  selectedFiles = [];
  customerName = '';
  customerEmail = '';
  customerPhone = '';
  customerLegalTermsAccepted = false;
  alertMessage = false;
  alertName = false;
  alertEmail = false;
  alertPhone = false;
  alertTerms = false;
  alertImageSize = false;
  newRequest: Post = {
    mensaje: '',
    especialidad: ''
  };
  requestOK = false;
  inputDisabled = false;
  
  constructor(public modal: NgbActiveModal,
              private catService: CategoriesService,
              private statesService: StatescitiesService,
              private fileService: FilesService,
              private domSanitizer: DomSanitizer,
              private userService: UsersService,
              private postService: PostsService) { }

  ngOnInit(): void {
    this.catService.getSubcatWithCat()
      .subscribe(res => {
        this.categories = res['data'];
        console.log(this.categories);
        
      });
  }

  search(event){
    const value:string = event.target.value;
    console.log(value);
    this.showCategories = [];
    // this.showCategories = this.categories.find(cat => cat.subcategory_description.indexOf(event.target.value) !== -1);
    this.categories.forEach( cat => {
      let text = (cat.category_name+','+cat.category_description+','+cat.subcategory_name+','+cat.subcategories_description).toLowerCase();
      if (event.target.value !== '' && 
          event.target.value !== ' ' &&
          text.includes(value.toLowerCase())) {
        // console.log(cat.subcategories_description);
        this.showCategories.push(cat);
      }
      
    });
    console.log(this.showCategories);
    
  }

  selection1(cat){
    this.selectedText = cat.category_name  + ':' + cat.subcategories_description;
    this.showCategories = [];
    console.log(this.selectedText);
    this.questions[0].answer = cat.category_id;
    this.next();
  }

  checkZip(event){
    this.sepomex = false;
    console.log(this.zipCode);
    
    if (event.length === 4) {
      this.statesService.getSepomexByZipCountry(this.zipCode, 'AR')
      .subscribe(res => {
        console.log(res);
        if (res['code']==='200') {
          this.zipResult = res['data'];
          this.sepomex = true;
        }
      });
      this.questions[1].answer = event;
    }
  }

  selection2(sepomex){
    // console.log(sepomex);
    this.questions[1].answer = sepomex.id;
    this.next();
  }

  selection3(urgency){
    // console.log(urgency);
    this.questions[2].answer = urgency;
    this.next();
  }

  selection4(quotations){
    // console.log(quotations);
    this.questions[3].answer = quotations;
    this.next();
  }

  selectFile(event){
    this.alertImageSize = false;
    const files = event.target.files;
    console.log(files[0]['size']);
    if (files[0]['size'] < 2000000) {
      this.selectedFiles.push(files);
    } else {
      this.alertImageSize = true;
    }
    
  }

  removeImage(img){
    // console.log(img);
    this.selectedFiles.forEach((image, index)=>{
      if (image === img) {
        this.selectedFiles.splice(index,1);
      }
    })
  }

  publish(){
    this.alertEmail = false;
    this.alertName  = false;
    this.alertPhone = false;
    this.alertTerms = false;
    let requestOK = true;

    if (this.customerName === '') {
      this.alertName = true;
      requestOK = false;
    }
    if (this.customerEmail === '') {
      this.alertEmail = true;
      requestOK = false;
    }
    if (this.customerPhone === '') {
      this.alertPhone = true;
      requestOK = false;
    }
    if (!this.customerLegalTermsAccepted ) {
      this.alertTerms = true;
      requestOK = false;
    }

    if (requestOK) {
      this.createUser();
    }
  }

  createUser(){
    if (!localStorage.getItem('token')) {
      let usuario: Usuario = {
        avatar: 'av-1.png',
        nombre: this.customerName,
        name: this.customerName,
        email: this.customerEmail,
        password: '',
        pass: '',
        userType: 'CLIENTE',
        enabled: 1,
        background_image: '',
        alias: '',
        register_type: 'AUTOMATIC',
        country_id: 'AR'
      };
      this.userService.registro(usuario)
        .then(res=>{
          console.log('REGISTRO:');
          console.log(res);
          this.login();
        })
    } else {
      if (this.userService.validaTokenByString(localStorage.getItem('token'))) {
        this.createRequest();
      } else {
        this.login();
      }
      
    }
  }

  login(){
    const email     = this.customerEmail;
    const password  = ''; 
    const loginType = 'automatic';
    const alias     = '';
    const name      = this.customerName;
    const userType  = 'CLIENTE'; 
    const registerType = 'AUTOMATIC';
    const avatar    = '';

    this.userService.login(email, password, loginType, alias, name, userType,registerType, avatar)
      .then(res => {
        console.log('LOGIN:');
        console.log(res);
        this.createRequest();
      })
  }

  createRequest(){
    this.newRequest.mensaje = this.customerMessage;
    this.newRequest.categoria = this.questions[0].answer;
    this.postService.createPost(this.newRequest, this.selectedFiles)
      .then(res=>{
        console.log('CREATE REQUEST:');
        console.log(res);
        if (res['code'] === '200') {
          this.requestOK = true;
          this.inputDisabled = true;
        }
      })
  }

  back(){
    let i=0;
    this.questions.forEach((q , index)=>{
      if (q.active) {
        i = index;
      }
    });
    this.questions[i].active = false;
    this.questions[i-1].active = true;
    this.currentQuestion -= 1;
  }

  next(){
    this.alertMessage = false;
    // console.log(this.currentQuestion);
    
    if (!this.questions[this.currentQuestion].last) {
      this.questions[this.currentQuestion].active = false;
      this.currentQuestion += 1;
      this.questions[this.currentQuestion].active = true;
      if (this.questions[this.currentQuestion].enabled===false) {
        this.next();
      }
    }

    if (this.currentQuestion-1 === 4 && this.customerMessage === '') {
      this.alertMessage = true;
      this.back();
    }
    
     
    
  }

  viewResults(){
  
  }


}

import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AskQuotationComponent } from '../customers/ask-quotation/ask-quotation.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private ngModalService: NgbModal) { }

  ngOnInit(): void {
  }

  quotation(){
    // this.clear();
    const ref = this.ngModalService.open(AskQuotationComponent, {size:'lg', centered: true, scrollable: true, backdrop:'static', keyboard:false});
    // ref.componentInstance.customer = this.cust;
    // ref.componentInstance.mode = 'add';
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsersService } from './users.service';
import { environment } from '../../environments/environment.prod';
import { Subcategory } from '../interfaces/interfaces';

const URL2 = environment.url2;

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(private http: HttpClient,
              private usuarioService: UsersService) { }

  getCategories() {
    const authorization = this.usuarioService.getUsuario().token;
    const headers = new HttpHeaders({
      Authorization: authorization
    });
    const api = URL2 + 'categories';
    return this.http.get(api , {headers} );
  }

  getCategoriesByCountry() {
    // const authorization = this.usuarioService.getUsuario().token;
    const headers = new HttpHeaders({
      // Authorization: authorization
    });
    const api = URL2 + 'getCategoriesByCountry/AR';
    return this.http.get(api , {headers} );
  }

  getSubcategories(id: string){
    const authorization = this.usuarioService.getUsuario().token;
    const headers = new HttpHeaders({
      Authorization: authorization
    });
    const api = URL2 + 'getSubcategories/' + id;
    return this.http.get<Subcategory>(api , {headers} );
  }

  getSubcatWithCat() {
    // const authorization = this.usuarioService.getUsuario().token;
    const headers = new HttpHeaders({
      // Authorization: authorization
    });
    const api = URL2 + 'getSubcatWithCat/AR';
    return this.http.get(api , {headers} );
  }

}

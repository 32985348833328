<app-header></app-header>

<!-- Page Header Start -->
<div
class="container-fluid page-header py-5 mb-5 wow fadeIn"
data-wow-delay="0.1s"
>
<div class="container text-center py-5">
  <h1 class="display-4 text-white animated slideInDown mb-4">
    Contacto
  </h1>
  <nav aria-label="breadcrumb animated slideInDown">
    <ol class="breadcrumb justify-content-center mb-0">
      <li class="breadcrumb-item">
        <a class="text-white" href="index.html">Home</a>
      </li>
      <!-- <li class="breadcrumb-item">
        <a class="text-white" href="#">Pages</a>
      </li> -->
      <li class="breadcrumb-item text-primary active" aria-current="page">
        Contacto
      </li>
    </ol>
  </nav>
</div>
</div>
<!-- Page Header End -->

<!-- Contact Start -->
<div class="container-xxl py-5">
<div class="container">
  <div class="row g-5">
    <!-- People start -->
    <!-- <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
      <div class="row g-4 align-items-center">
        <div class="col-sm-6">
          <img class="img-fluid" src="img/team-1.jpg" alt="" />
        </div>
        <div class="col-sm-6">
          <h3 class="mb-0">Full Name</h3>
          <p>Head of Sales</p>
          <h6>Contact Details</h6>
          <p>
            Lorem ipsum dolor sit amet conse elit sed eiu smod lab ore.
          </p>
          <p class="mb-0">Call: +012 345 6789</p>
          <p class="mb-0">Email: sales@example.com</p>
        </div>
      </div>
    </div>
    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
      <div class="row g-4 align-items-center">
        <div class="col-sm-6">
          <img class="img-fluid" src="img/team-2.jpg" alt="" />
        </div>
        <div class="col-sm-6">
          <h3 class="mb-0">Full Name</h3>
          <p>Head of Marketing</p>
          <h6>Contact Details</h6>
          <p>
            Lorem ipsum dolor sit amet conse elit sed eiu smod lab ore.
          </p>
          <p class="mb-0">Call: +012 345 6789</p>
          <p class="mb-0">Email: sales@example.com</p>
        </div>
      </div>
    </div> -->
    <!-- People end -->

    <!-- Map start -->
    <!-- src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd" -->

    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style="min-height: 450px">
      <div class="position-relative h-100">
        <iframe
          class="position-relative w-100 h-100"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=Yerba Buena, Tucuman+(Wifix)&amp;t=&amp;z=8&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          frameborder="0"
          style="min-height: 450px; border: 0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </div>
    <!-- Map end -->

    <!-- Message start -->
    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
      <div class="border-start border-5 border-primary ps-4 mb-5">
        <h6 class="text-body text-uppercase mb-2">Contacto</h6>
        <h1 class="display-6 mb-0">
          Envíanos tu consulta.
        </h1>
      </div>
      <p class="mb-4">
        Si quieres saber mas sobre nuestro servicio o tienes preguntas relacionadas al mismo, por favor eníanos tu mensaje y te responderemos a la brevedad.
        <!-- <a href="https://htmlcodex.com/contact-form">Download Now</a>. -->
      </p>
      <form>
        <div class="row g-3">
          <div class="col-md-6">
            <div class="form-floating">
              <input
                type="text"
                class="form-control border-0 bg-light"
                id="name"
                placeholder="Your Name"
              />
              <label for="name">Tu nombre</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating">
              <input
                type="email"
                class="form-control border-0 bg-light"
                id="email"
                placeholder="Your Email"
              />
              <label for="email">Tu email</label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating">
              <input
                type="text"
                class="form-control border-0 bg-light"
                id="subject"
                placeholder="Subject"
              />
              <label for="subject">Asunto</label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating">
              <textarea
                class="form-control border-0 bg-light"
                placeholder="Leave a message here"
                id="message"
                style="height: 150px"
              ></textarea>
              <label for="message">Mensaje</label>
            </div>
          </div>
          <div class="col-12">
            <button class="btn btn-primary py-3 px-5" type="submit">
              Enviar mensaje
            </button>
          </div>
        </div>
      </form>
    </div>
    <!-- Message end -->

  </div>
</div>
</div>
<!-- Contact End -->

<app-footer></app-footer>
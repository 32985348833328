<div class="modal-body row bg-dark">
    <div class="p-4">
        <h4 class="mt-3 text-center text-white">Login</h4>
        <div class="alert alert-danger" role="alert" *ngIf="alertLogin">
            <p style="margin-left: 10px;">Usuario o contraseña incorrecto</p> 
            </div>
        <div class="row g-3 mt-2">
            <div class="col-md-12">
                <div class="alert alert-danger" role="alert" *ngIf="alertUser">
                <p style="margin-left: 10px;">Debes introducir tu correo electrónico o DNI</p> 
                </div>
                <div class="form-floating">
                    <input 
                        name="email"
                        [(ngModel)]="email"
                        type="email"
                        class="form-control border-0 bg-light"
                        id="email"
                        placeholder="Correo Electrónico"/>
                    <label for="email">Correo Electrónico o DNI</label>
                </div>
            </div>
            <div class="col-md-12">
                <div class="alert alert-danger" role="alert" *ngIf="alertPassword">
                <p style="margin-left: 10px;">Debes introducir tu contraseña</p> 
                </div>
                <div class="form-floating">
                    <input 
                        name="pass"
                        [(ngModel)]="pass"
                        type="password"
                        class="form-control border-0 bg-light"
                        id="pass"
                        placeholder="Contraseña"
                    />
                    <label for="pass">Contraseña</label>
                </div>
            </div>
        </div>
        <div class="row g-3 mt-3" style="text-align: center;">
            <button class="btn btn-primary btn-lg block" (click)="login()">Ingresar</button>
        </div>
        <div class="row g-3 mt-3 text-center text-white pointer" >
            <a  (click)="forgotPass()" >Olvidé mi contraseña</a>
        </div>
    </div>
    
</div>



<app-header></app-header>

<!-- Page Header Start -->
<div
class="container-fluid page-header py-5 mb-5 wow fadeIn"
data-wow-delay="0.1s"
>
<div class="container text-center py-5">
  <h1 class="display-4 text-white animated slideInDown mb-4">
    Nuestros servicios
  </h1>
  <nav aria-label="breadcrumb animated slideInDown">
    <ol class="breadcrumb justify-content-center mb-0">
      <li class="breadcrumb-item">
        <a class="text-white" href="index.html">Home</a>
      </li>
      <!-- <li class="breadcrumb-item">
        <a class="text-white" href="#">Pages</a>
      </li> -->
      <li class="breadcrumb-item text-primary active" aria-current="page">
        Nuestros servicios
      </li>
    </ol>
  </nav>
</div>
</div>
<!-- Page Header End -->

<!-- Service Start -->
<div class="container-xxl py-5">
<div class="container">
  <div class="row g-5 align-items-end mb-5">
    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
      <div class="border-start border-5 border-primary ps-4">
        <h6 class="text-body text-uppercase mb-2">Especialidades</h6>
        <h1 class="display-6 mb-0">
          Servicio doméstico, cuidado de personas y artes culinarias.
        </h1>
      </div>
    </div>
    <div class="col-lg-6 text-lg-end wow fadeInUp" data-wow-delay="0.3s">
      <a class="btn btn-primary py-3 px-5" href="">Mas servicios</a>
    </div>
  </div>
  <div class="row g-4 justify-content-center">
    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
      <div class="service-item bg-light overflow-hidden h-100">
        <img class="img-fluid" src="img/service-1.jpg" alt="" />
        <div class="service-text position-relative text-center h-100 p-4">
          <h5 class="mb-3">Aseo general</h5>
          <p>
            Aseo general, limpieza profunda.
          </p>
          <a class="small" href=""
            >LEER MAS<i class="fa fa-arrow-right ms-3"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
      <div class="service-item bg-light overflow-hidden h-100">
        <img class="img-fluid" src="img/service-2.jpg" alt="" />
        <div class="service-text position-relative text-center h-100 p-4">
          <h5 class="mb-3">Cuidado de Niños</h5>
          <p>
            Cuidado de niños y dolescentes. Rangos por edades.
          </p>
          <a class="small" href=""
            >LEER MAS<i class="fa fa-arrow-right ms-3"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
      <div class="service-item bg-light overflow-hidden h-100">
        <img class="img-fluid" src="img/service-2.jpg" alt="" />
        <div class="service-text position-relative text-center h-100 p-4">
          <h5 class="mb-3">Cuidado de Adultos</h5>
          <p>
            Cuidado de adultos mayores. Cuidados especiales. Aseo corporal. Transportación de adultos mayores. 
          </p>
          <a class="small" href=""
            >LEER MAS<i class="fa fa-arrow-right ms-3"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
      <div class="service-item bg-light overflow-hidden h-100">
        <img class="img-fluid" src="img/service-3.jpg" alt="" />
        <div class="service-text position-relative text-center h-100 p-4">
          <h5 class="mb-3">Cocina</h5>
          <p>
            Preparación de comidas en general. Platos especiales. Comidas para eventos.
          </p>
          <a class="small" href=""
            >LEER MAS<i class="fa fa-arrow-right ms-3"></i
          ></a>
        </div>
      </div>
    </div>
    <!-- <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
      <div class="service-item bg-light overflow-hidden h-100">
        <img class="img-fluid" src="img/service-4.jpg" alt="" />
        <div class="service-text position-relative text-center h-100 p-4">
          <h5 class="mb-3">Electricidad</h5>
          <p>
            Certificación de instalaciones. Solución a corto circuitos. Nuevas instalaciones. Todo lo relacionado a la electricidad de tu casa, lo encontrarás aquí.
          </p>
          <a class="small" href=""
            >LEER MAS<i class="fa fa-arrow-right ms-3"></i
          ></a>
        </div>
      </div>
    </div> -->
    <!-- <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
      <div class="service-item bg-light overflow-hidden h-100">
        <img class="img-fluid" src="img/service-5.jpg" alt="" />
        <div class="service-text position-relative text-center h-100 p-4">
          <h5 class="mb-3">Arquitectura y diseño de interiores</h5>
          <p>
            Proyectos de arquitectura en general. Diseño de tu proyecto. Todo lo relacionado a la arquitectura y diseño lo encontrarás aquí.
          </p>
          <a class="small" href=""
            >LEER MAS<i class="fa fa-arrow-right ms-3"></i
          ></a>
        </div>
      </div>
    </div> -->
    <!-- <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
      <div class="service-item bg-light overflow-hidden h-100">
        <img class="img-fluid" src="img/service-6.jpg" alt="" />
        <div class="service-text position-relative text-center h-100 p-4">
          <h5 class="mb-3">Remodelación y construcción</h5>
          <p>
            Diseño y ejecución de remodelaciones. Pisos, cocinas, techos, nuevos ambientes, etc. Todo lo relacionado a la remodelación de tu casa lo encontrarás aquí. Ya tienes tu proyecto listo? Aquí encontrarás freelancers que te ayudarán con la construcción de tu casa. Todo lo relacionado a la construcción lo encontrarás aquí.
          </p>
          <a class="small" href=""
            >LEER MAS<i class="fa fa-arrow-right ms-3"></i
          ></a>
        </div>
      </div>
    </div> -->
  </div>
</div>
</div>
<!-- Service End -->

<!-- Appointment Start -->
<div class="container-xxl py-5">
<div class="container">
  <div class="row g-5">
    <div class="col-lg-5 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
      <div class="border-start border-5 border-primary ps-4 mb-5">
        <h6 class="text-body text-uppercase mb-2">Pide un servicio</h6>
        <h1 class="display-6 mb-0">
          Con una empresa o profesional independiente.
        </h1>
      </div>
      <p class="mb-0">
        Coloca tu pedido y uno o mas profesionales se pondrán en contacto contigo para darte su cotización y acordar el dia y hora para comenzar tu proyecto o servicio. 
      </p>
    </div>
    <div class="col-lg-7 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
      <form>
        <div class="row g-3">
          <div class="col-sm-6">
            <div class="form-floating">
              <input
                type="text"
                class="form-control bg-light border-0"
                id="gname"
                placeholder="Gurdian Name"
              />
              <label for="gname">Tu nombre</label>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-floating">
              <input
                type="email"
                class="form-control bg-light border-0"
                id="gmail"
                placeholder="Gurdian Email"
              />
              <label for="gmail">Tu email</label>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-floating">
              <input
                type="text"
                class="form-control bg-light border-0"
                id="cname"
                placeholder="Child Name"
              />
              <label for="cname">Tu celular</label>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-floating">
              <input
                type="text"
                class="form-control bg-light border-0"
                id="cage"
                placeholder="Child Age"
              />
              <label for="cage">Tipo de Servicio</label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating">
              <textarea
                class="form-control bg-light border-0"
                placeholder="Leave a message here"
                id="message"
                style="height: 100px"
              ></textarea>
              <label for="message">Mensaje</label>
            </div>
          </div>
          <div class="col-12">
            <button class="btn btn-primary w-100 py-3" type="submit">
              Envía tu solicitud
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
</div>
<!-- Appointment End -->

<app-footer></app-footer>
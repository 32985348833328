<h2 class="text-center mt-2 text-tertiary">Mis datos personales.</h2>
<p class="text-center text-danger" *ngIf="changes">Guardando...</p>
<p class="text-center text-primary" *ngIf="!changes">Actualizado</p>
<!-- <div>
    <button class="btn btn-outline-primary py-3 px-5" type="submit" (click)="finish()">
        Finalizar
    </button>
    <span class="ml-5" *ngIf="changes">   Salvando datos...</span>
</div> -->
<br>

<form (ngSubmit)="save()">
    <div class="row g-3">
        <!-- Names  -->
        <div class="col-md-6">
            <div class="form-floating">
            <input
                type="text"
                class="form-control border-0 bg-light"
                name="names"
                id="names"
                placeholder="Nombre"
                [(ngModel)]="names"
                (ngModelChange)="onChange()"/>
            <label for="names">Nombre/s</label>
            </div>
        </div>
        <!-- Last Names -->
        <div class="col-md-6">
            <div class="form-floating">
            <input
                type="text"
                class="form-control border-0 bg-light"
                name="lastNames"
                id="lastnames"
                placeholder="Apellido"
                [(ngModel)]="lastNames"
                (ngModelChange)="onChange()"
            />
            <label for="lastnames">Apellido/s</label>
            </div>
        </div>
        <!-- Address  -->
        <div class="col-12">
            <div class="form-floating">
            <input
                type="text"
                class="form-control border-0 bg-light"
                name="address"
                id="address"
                placeholder="Domicilio"
                [(ngModel)]="address"
                (ngModelChange)="onChange()"
            />
            <label for="address">Domicilio</label>
            </div>
        </div>
        <!-- Zip Code -->
        <div class="col-md-3">
            <div class="form-floating">
                <input
                    type="text"
                    class="form-control border-0 bg-light"
                    name="zipCode"
                    id="zipCode"
                    placeholder="Código Postal"
                    [(ngModel)]="zipCode"
                    (ngModelChange)="checkZip($event)"
                />
                <label for="zipCode">Código Postal</label>
            </div>
        </div>
        <!-- State , City , Neighborhood  -->
        <div class="col-md-9 rounded bg-light">
            <div *ngIf="!sepomex" class="pt-3">
                <p>Estado, Municipio y Colonia</p> 
            </div>
            
            <div *ngIf="sepomex" class="rounded">
                <p> <b>{{ zipResult[0].estado }}</b></p>
                <div class="form-check form-check-inline" *ngFor="let sepomex of zipResult" style="font-size: 14px;">
                  <input *ngIf="selectedSepomex && sepomex.id===selectedSepomex.id" class="form-check-input" type="radio" name="sepomex" id="sepomex" checked (change)="selectSepomex(sepomex)" >
                  <input *ngIf="!selectedSepomex || sepomex.id!==selectedSepomex.id"class="form-check-input" type="radio" name="sepomex" id="sepomex" (change)="selectSepomex(sepomex)" >
                  <label style="margin-left: 10px;" class="form-check-label" for="sepomex"><p>{{ sepomex.ciudad }} </p></label>
                </div>
            </div>
        </div>
        <!-- Mobile Phone  -->
        <div class="col-md-6">
            <div class="form-floating">
            <input
                type="text"
                class="form-control border-0 bg-light"
                name="phoneMobile"
                id="phoneMobile"
                placeholder="Teléfono Celular"
                [(ngModel)]="phoneMobile" name="phoneMobile"
                (ngModelChange)="onChange()"
            />
            <label for="phoneMobile">Teléfono Celular</label>
            </div>
        </div>
        <!-- Other Phone  -->
        <div class="col-md-6">
            <div class="form-floating">
            <input
                type="text"
                class="form-control border-0 bg-light"
                name="phoneOther"
                id="phoneOther"
                placeholder="Otro Teléfono"
                [(ngModel)]="phoneOther" name="phoneOther"
                (ngModelChange)="onChange()"
            />
            <label for="phoneOther">Otro Teléfono</label>
            </div>
        </div>

        <!-- Experience  -->
        <div class="col-12">
            <div class="form-floating">
            <textarea
                class="form-control border-0 bg-light"
                placeholder="Escribe tu trayectoria para que puedan leerla tus potenciales clientes."
                [(ngModel)]="labourBackground"
                (ngModelChange)="onChange()"
                name="labourBackground"
                id="labourBackground"
                style="height: 150px"
            ></textarea>
            <label for="labourBackground" style="font-style: italic;"> Escribe a cerca de vos, tus aptitudes, lo que sabes hacer. Esta descripción la leeran tus potenciales empleadores.</label>
            </div>
        </div>

        <div class="col-12">
            <button class="btn btn-primary py-3 px-5" type="submit" (click)="finish()">
                Finalizar
            </button>
        </div>
    </div>
  </form>

  

  
  

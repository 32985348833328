import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConnectionService } from './connection.service';
import { Router } from '@angular/router';
import { Usuario } from '../interfaces/interfaces';

const URL2 = environment.url2;

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  token: string = null;
  private usuario: Usuario = {};

  constructor(private http: HttpClient,
              private route: Router,
              private conn: ConnectionService) { }

login( email: string , password: string , 
      loginType: string, alias: string,
      name: string, userType: string, 
      registerType: string, avatar: string) {
    // const formData = new FormData();
    // formData.append('email', email);
    // formData.append('password', password);
    // formData.append('logintype', loginType);
    // formData.append('alias', alias);
  const data = {
    email,
    password,
    logintype: loginType,
    alias,
    name,
    userType,
    register_type: registerType,
    avatar
  };

  const httpHeaders = new HttpHeaders();
  httpHeaders.append('Authorization', '');
  const api = URL2 + 'api/auth/login';
  return new Promise( resolve => {
    // Llamar servicio de login en mi backend
    this.http.post(api, data, {headers: httpHeaders})
      .subscribe( resp => {
        console.log( resp );
        if (resp['code'] === '200') {
          const login = resp['data'];
          this.guardarToken(login[ 'token' ]);
          localStorage.setItem('email', login['email']);
          localStorage.setItem('token', login['token']);
          localStorage.setItem('userId', login['userId']);
          localStorage.setItem('avatar', login['avatar']);
          localStorage.setItem('userName', login['userName']);
          localStorage.setItem('userRole', login['userRole']);
          localStorage.setItem('companyName', login['company_name']);
          localStorage.setItem('companyId', login['company_id']);
          localStorage.setItem('companyLogo', login['logo_image']);
          localStorage.setItem('alias', login['alias']);
          localStorage.setItem('enabled', login['enabled']);
          localStorage.setItem('countryId', login['country_id']);

          resolve(true);
        } else {
          this.token = null;
          // this.storage.clear();
          localStorage.clear();
          console.log(resp);
          resolve(false);
        }
      } );
    });

  }

  logout() {
    localStorage.removeItem('userId');
    localStorage.removeItem('alias');
    localStorage.removeItem('avatar');
    localStorage.removeItem('email');
    localStorage.removeItem('enabled');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('userName');
    localStorage.removeItem('token');
  }

  async guardarToken( token: string ) {
    this.token = token;
    // await this.storage.set('token' , token);
    localStorage.setItem('token', token);
    await this.validaToken();
  }

  async cargarToken() {
    // this.token = await this.storage.get('token') || null;
    this.token = localStorage.getItem('token');
  }

  async validaToken(): Promise<boolean> {
    const connOk = this.conn.checkConnection2();
    await this.cargarToken();
    if (!this.token) {
      this.route.navigate(['login']);
      return Promise.resolve(false);
    }
    const formData = new FormData();
    const httpHeaders = new HttpHeaders(
      {Authorization: this.token}
      );
    const api = URL2 + 'api/auth/verifyToken';
    return new Promise( resolve => {
      // Llamar servicio de login en mi backend
      this.http.post(api, formData, {headers: httpHeaders})
        .subscribe( resp => {
          if (resp['code'] == '200') {
            this.usuario = resp['data'];
            resolve(true);
          } else {
            this.token = null;
            localStorage.clear();
            this.route.navigate(['login']);
            resolve(false);
          }
        } );
    });
  }

  async validaTokenByString(token:string): Promise<boolean> {
    const connOk = this.conn.checkConnection2();
    // await this.cargarToken();
    // if (!this.token) {
    //   this.route.navigate(['login']);
    //   return Promise.resolve(false);
    // }
    const formData = new FormData();
    const httpHeaders = new HttpHeaders(
      {Authorization: token}
      );
    const api = URL2 + 'api/auth/verifyToken';
    return new Promise( resolve => {
      // Llamar servicio de login en mi backend
      this.http.post(api, formData, {headers: httpHeaders})
        .subscribe( resp => {
          if (resp['code'] == '200') {
            this.usuario = resp['data'];
            resolve(true);
          } else {
            this.token = null;
            localStorage.clear();
            this.route.navigate(['login']);
            resolve(false);
          }
        } );
    });
  }

  actualizarUsuario(usuario: Usuario) {
    const authorization = this.token;
    const headers = new HttpHeaders({
      Authorization: authorization
    });
    const api = URL2 + 'users/' + usuario._id;
    return new Promise( resolve => {
      this.http.put(api, usuario , { headers })
        .subscribe( resp => {
          console.log(resp);
          if ( resp['code'] === '200' ) {
            // this.guardarToken( resp['token'] );
            resolve(true);
          } else {
            resolve(false);
          }
        } );
    });
  }

  resetpass(email: string) {
    const headers = new HttpHeaders({});
    const data = {
      email
    };
    const api = URL2 + 'api/auth/resetPassword';
    return this.http.post<any>(api , data , {headers} );
  }

  changepass(newPass: string) {
    const authorization = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: authorization
    });
    const formadata = new FormData();
    formadata.append('newpassword', newPass);
    const api = URL2 + 'api/auth/changePassword';

    return new Promise( resolve => {
      this.http.post(api, formadata , {headers} )
        .subscribe(  async resp => {
          resolve( resp );
        } );
    } );
  }

  getUsuario() {
    // console.log('Obteniendo datos del usuario...');
    // console.log(this.usuario);
    if ( !this.usuario._id ) {
      this.validaToken();
    }
    this.usuario._id = localStorage.getItem('userId');
    this.usuario.alias = localStorage.getItem('alias');
    this.usuario.avatar = localStorage.getItem('avatar');
    this.usuario.email = localStorage.getItem('email');
    this.usuario.enabled = Number(localStorage.getItem('enabled'));
    this.usuario.id = localStorage.getItem('userId');
    this.usuario.name = localStorage.getItem('userName');
    this.usuario.nombre = localStorage.getItem('userName');
    this.usuario.token = localStorage.getItem('token');
    // console.log(this.usuario);
    // return { ...this.usuario };
    // console.log(this.usuario);
    
    return this.usuario;
  }

  registro( usuario: Usuario ) {
    usuario.name = usuario.nombre;
    usuario.pass = usuario.password;
    // console.log(usuario);
    if (usuario.pass === '') {
      usuario.pass = Math.random().toString(36).slice(-8);
      console.log(usuario.pass);
    }

    const headers = new HttpHeaders({
    });
    const api = URL2 + 'users';
    return new Promise( resolve => {
      this.http.post(api, usuario , {headers} )
        .subscribe( resp => {
          // console.log('Resultado de Crear Usuario:');
          // console.log( resp );
          if ( resp['code'] === '200' ) {
            // await this.guardarToken(resp[ 'token' ]);
            resolve( resp );
            const data = resp['data'];
            localStorage.setItem('userId', data['id']);
          } else {
            // console.log(resp);
            this.token = null;
            // this.storage.clear();
            localStorage.clear();
            resolve( resp );
          }
        } );
    });
  }

}

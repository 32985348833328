import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { SupplierCheckList, SupplierCategory, SupplierDocument } from '../interfaces/interfaces';

const URL2 = environment.url2;

@Injectable({
  providedIn: 'root'
})
export class SuppliersService {

  constructor(private http: HttpClient) { }

  updateSupplierData(data: any) {
    const authorization = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: authorization
    });
    // const data = {
    //     names: names,
    //     last_names: lastNames
    // };
    const api = URL2 + 'updateSupplierData';
    return new Promise( resolve => {
      this.http.post(api , data , { headers } )
        .subscribe( res => {
          resolve(res);
        } );
    } );
  }

  updateSupplierLegalTerms(supp: SupplierCheckList) {
    const authorization = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: authorization
    });
    const data = {
        accepted_terms_conditions: supp.accepted_terms_conditions,
        accepted_privacy_statement: supp.accepted_privacy_statement
    };
    const api = URL2 + 'updateSupplierLegalTerms/' + supp.id;
    // console.log(data);
    return new Promise( resolve => {
      this.http.post(api, data , { headers })
        .subscribe( resp => {
            resolve(resp);
        } );
    });
  }

  generateSecCode() {
    const authorization = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: authorization
    });
    const data = {};
    const api = URL2 + 'generateSecCode';
    // console.log(data);
    return new Promise( resolve => {
      this.http.post(api, data , { headers })
        .subscribe( resp => {
            resolve(resp);
        } );
    });
  }

  verifySecCode(secCode) {
    const authorization = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: authorization
    });
    const data = {
      'sec_code': secCode
    };
    
    const api = URL2 + 'verifySecCode';
    // console.log(data);
    return new Promise( resolve => {
      this.http.post(api, data , { headers })
        .subscribe( resp => {
            resolve(resp);
        } );
    });
  }

  getSupplierDetails(id: string) {
    // Get by userId
    const authorization = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: authorization
    });
    const api = URL2 + 'getSupplierByUserId/' + id;
    // console.log(data);
    return this.http.get(api , {headers} );
  }

  createSupplierCategory( supplierCategory: SupplierCategory) {
    console.log('Hola');
    const authorization = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: authorization
    });
    const api = URL2 + 'appsuppliercategories';
    return new Promise( resolve => {
      this.http.post(api, supplierCategory , { headers })
      .subscribe( resp => {
        console.log(resp);
        resolve(resp);
      } );
    });
  }

  deleteSupplierCategory(id: string) {
    const authorization = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: authorization
    });
    const api = URL2 + 'appsuppliercategories/' + id;
    return new Promise( resolve => {
      this.http.delete(api, { headers })
        .subscribe( resp => {
          // console.log(resp);
          resolve(resp);
        } );
    });
  }

  getSupplierCategories(supplierId: string) {
    const authorization = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: authorization
    });
    const api = URL2 + 'getSupplierCategories/' + supplierId;
    return this.http.get(api , {headers} );
  }

  getSupplierDocuments(supplierId: string) {
    const authorization = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: authorization
    });
    const api = URL2 + 'getSupplierDocuments/' + supplierId;
    return this.http.get(api , {headers} );
  }

  updateSupplierDocument(doc: SupplierDocument) {
    const authorization = localStorage.getItem('token');
    const headers = new HttpHeaders({
      Authorization: authorization
    });
    // console.log(doc);
    const api = URL2 + 'appsupplierdocuments/' + doc.id;
    return new Promise( resolve => {
      this.http.put(api, doc , { headers })
        .subscribe( resp => {
            resolve(resp);
        } );
    });
  }

}


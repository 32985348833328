import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Sepomex, Supplier } from '../../../interfaces/interfaces';
import { StatescitiesService } from '../../../services/statescities.service';
import { SuppliersService } from '../../../services/suppliers.service';
import { UsersService } from '../../../services/users.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmationComponent } from '../../helpers/modal-confirmation/modal-confirmation.component';

@Component({
  selector: 'app-supplier-personal-data',
  templateUrl: './supplier-personal-data.component.html',
  styleUrls: ['./supplier-personal-data.component.css']
})
export class SupplierPersonalDataComponent implements OnInit {
  names = '';
  lastNames = '';
  address = '';
  zipCode = '';
  sepomex = false;
  sepomexId = '';
  currentSepomex: Sepomex;
  zipResult: any;
  selectedSepomex: Sepomex = {
    id: '',
    idEstado: '',
    estado: '',
    idMunicipio: '',
    municipio: '',
    ciudad: '',
    zona: '',
    cp: '',
    asentamiento: '',
    tipo: ''
  };
  phoneMobile = '';
  phoneOther = '';
  labourBackground = '';
  supplier: Supplier;
  currentSupplier: Supplier;
  changes = false;

  constructor(private statesService: StatescitiesService,
              private supplierService: SuppliersService,
              private userService: UsersService,
              private router: Router,
              private ngModalService: NgbModal) { }

  ngOnInit(): void {
    this.getSupplierData();
  }

  getSupplierData(){
    const userId = localStorage.getItem('userId');
    if (userId) {
      this.supplierService.getSupplierDetails(userId)
        .subscribe(res=>{
          console.log(res);
          
          if (res['code'] === '200') {
            this.currentSupplier = res['data'];
            this.supplier = this.currentSupplier;
            this.names = this.supplier.names;
            this.lastNames = this.supplier.last_names;
            this.address = this.supplier.address;
            this.phoneMobile = this.supplier.phone_mobile;
            this.phoneOther = this.supplier.phone_other;
            this.labourBackground = this.supplier.labour_background;
            this.sepomexId= this.supplier.sepomex_id;
            this.statesService.getSepomexById(this.sepomexId)
              .subscribe(res1=>{
                if (res1['code'] === '200') {
                  this.selectedSepomex = res1['data'];
                  this.zipCode = this.selectedSepomex.cp;
                  this.checkZip(this.zipCode);
                }
              })
          }
        })
    }
  }

  checkZip(event){
    console.log(event);
    this.sepomex = false;
    if (event !== this.selectedSepomex.cp) {
      this.onChange();
    }
    
    if (event.length === 4) {
      this.statesService.getSepomexByZipCountry(this.zipCode, 'AR')
      .subscribe(res => {
        // console.log(res);
        if (res['code']==='200') {
          this.zipResult = res['data'];
          this.sepomex = true;
        }
      });
    }
  }

  selectSepomex(sepomex){
    this.selectedSepomex = sepomex;
    console.log(this.selectedSepomex);
    this.onChange();
  }

  save(){
    const generalData = {
      names: this.names,
      last_names: this.lastNames,
      phone_mobile: this.phoneMobile,
      stateId: this.selectedSepomex.idEstado,
      cityId: this.selectedSepomex.idMunicipio,
      zip_code: this.zipCode,
      sepomex_id: this.selectedSepomex.id,
      address: this.address,
      VAT_typeId: this.supplier.VAT_typeId,
      labour_background: this.labourBackground
    };
    // console.log(generalData);
    this.supplierService.updateSupplierData(generalData)
      .then(res => {
        console.log(res);
        if (res['code'] === '200') {
          this.supplier = res['data'];
          this.changes = false;
        }
        
      })
  }

  logout(){
    this.userService.logout();
    this.router.navigate(['/home']);
  }

  onChange(){
    this.changes = true;
    this.save();
  }

  ngOnDestroy(){
    if (this.changes) {
      console.log('Hay cambios pendientes, si cierras pederás los cmabios.');
    }
  }

  finish(){
    const ref = this.ngModalService.open(ModalConfirmationComponent, {centered: true});
    ref.componentInstance.title = 'Felicitaciones!!!';
    // ref.componentInstance.elementType = 'Profesional';
    // ref.componentInstance.elementName = ' ' + supplier.names + ' '+ supplier.last_names;
    // ref.componentInstance.action = 'aprobar';
    ref.componentInstance.text1 = 'Gracias por completar tu información, en breve recibirás novedades.';
    ref.componentInstance.text2 = 'Presiona Cancelar si quieres seguir editando tu información, de otro modo presiona Acpetar para salir.';
    ref.result.then( ok => {
      this.logout();
    },
    dis => {
      console.log(dis);
    })
  }

}

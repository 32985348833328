import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-connection-error',
  templateUrl: './connection-error.component.html',
  styleUrls: ['./connection-error.component.css']
})
export class ConnectionErrorComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit(): void {
  }

  login() {
    // this.navCtrl.navigateRoot('/login');
    this.route.navigate(['login']);
  }

}

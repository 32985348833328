
    <!-- Topbar Start -->
    <div class="container-fluid bg-light p-0">
        <div class="row gx-0 d-none d-lg-flex">
        <div class="col-lg-7 px-5 text-start">
            <!-- <div
            class="h-100 d-inline-flex align-items-center border-start border-end px-3"
            >
            <small class="fa fa-phone-alt me-2"></small>
            <small>+52 442 001 0001</small>
            </div> -->
            <div class="h-100 d-inline-flex align-items-center border-end px-3">
            <small class="far fa-envelope-open me-2"></small>
            <small>info@particulares.com.ar</small>
            </div>
            <div class="h-100 d-inline-flex align-items-center border-end px-3">
            <small class="far fa-clock me-2"></small>
            <!-- <small>Mon - Fri : 09 AM - 09 PM</small> -->
            <small>{{myDate}}</small>
            </div>
        </div>
        <div class="col-lg-5 px-5 text-end">
            <div class="h-100 d-inline-flex align-items-center">
            <!-- <a class="btn btn-square border-end border-start" href=""><i class="fab fa-facebook-f "></i></a> -->
            <!-- <a class="btn btn-square border-end" href=""><i class="fab fa-twitter"></i></a> -->
            <!-- <a class="btn btn-square border-end" href=""><i class="fab fa-linkedin-in"></i></a> -->
            <!-- <a class="btn btn-square border-end" href=""><i class="fab fa-instagram"></i></a> -->
            <!-- <a class="btn btn-square border-end border-start" target="_blank" href="https://www.facebook.com/fixcionhome"><i class="bi bi-facebook"></i></a> -->
            <!-- <a class="btn btn-square border-end" target="_blank" href="https://www.instagram.com/fixcion_home/"><i class="bi bi-instagram"></i></a> -->
            </div>
        </div>
        </div>
    </div>
    <!-- Topbar End -->

    <!-- Navbar Start -->
    <nav class="navbar navbar-expand-lg bg-tertiary navbar-light sticky-top px-4 px-lg-5 py-lg-0 transparent-90">
        <a [routerLink]="homeLink"class="navbar-brand d-flex align-items-center">
        <h1 class="m-0">
            <!-- <i class="fa fa-building text-primary me-3"></i> -->
            <img src="/assets/img/logo-particulares.png"  style="width: 50px;" alt="">
            <span style="color: var(--light);">PARTICULARES</span> 
        </h1>
        </a>
        <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
        <span class="navbar-toggler-icon"></span>
        </button>

        <!-- NavBar for Not logged user  -->
        <div class="collapse navbar-collapse" id="navbarCollapse" *ngIf="!loggedUser">
            <div class="navbar-nav ms-auto py-3 py-lg-0">
                <!-- <a href="index.html" class="nav-item nav-link active">Inicio</a> -->
                <a routerLink="/home" class="nav-item nav-link active text-light">Inicio</a>
                <a routerLink="/about" class="nav-item nav-link text-light">Quienes Somos</a>
                <a routerLink="/services" class="nav-item nav-link text-light">Nuestros Servicios</a>
                <a routerLink="/help-center-home" class="nav-item nav-link text-light">Centro de Ayuda</a>
                <!-- <a routerLink="/contact" class="nav -item nav-link">Contacto</a> -->
                <!-- <a *ngIf="loggedUser" class="nav -item nav-link" style="cursor: pointer;"  (click)="logout()"> <i class="bi bi-person-circle"></i>  Salir</a> -->
                <a *ngIf="!loggedUser" class="nav -item nav-link pointer text-primary" routerLink="/supplier-register" >  Registrarme</a>
                <a *ngIf="!loggedUser" class="nav -item nav-link pointer text-primary" (click)="login()"><i class="bi bi-box-arrow-in-right pl-1"></i>  Ingresar</a>
                <div class="nav-item dropdown" *ngIf="loggedUser">
                    <a
                      href="#"
                      class="nav-link dropdown-toggle text-primary"
                      data-bs-toggle="dropdown"
                      ><i class="bi bi-person-circle"></i> {{ userName }}</a
                    >
                    <div class="dropdown-menu bg-light m-0">
                      <a (click)="logout()" class="dropdown-item pointer"><i class="bi bi-box-arrow-right text-primary"></i>  Salir</a>
                      <a href="appointment.html" class="dropdown-item"><i class="bi bi-gear text-primary"></i>  Configuracion</a>
                      <a href="team.html" class="dropdown-item"><i class="bi bi-bag-check text-primary"></i>  Suscripción</a>
                    </div>
                  </div>
            </div>
        </div>
        <!-- NavBar for Logged user  -->
        <div class="collapse navbar-collapse" id="navbarCollapse" *ngIf="loggedUser">
            <div class="navbar-nav ms-auto py-3 py-lg-0">
                <a routerLink="/help-center-home" class="nav-item nav-link">Centro de Ayuda</a>
                <!-- <a routerLink="/contact" class="nav -item nav-link">Contacto</a> -->
                <!-- <a *ngIf="loggedUser" class="nav -item nav-link" style="cursor: pointer;"  (click)="logout()"> <i class="bi bi-person-circle"></i>  Salir</a> -->
                <div class="nav-item dropdown" >
                    <a
                      href="#"
                      class="nav-link dropdown-toggle text-primary"
                      data-bs-toggle="dropdown"
                      ><i class="bi bi-person-circle"></i> {{ userName }}</a
                    >
                    <div class="dropdown-menu bg-light m-0">
                      <a (click)="logout()" class="dropdown-item pointer"><i class="bi bi-box-arrow-right text-primary"></i>  Salir</a>
                      <a (click)="changePass()" class="dropdown-item"><i class="bi bi-key text-primary"></i> Cambiar contraseña</a>
                      <a (click)="reportIncident()" class="dropdown-item"><i class="bi bi-info-square text-primary"></i>  Reportar indidente</a>
                    </div>
                  </div>
            </div>
        </div>
    </nav>

    <!-- Navbar End -->

    <!-- Cookies -->
    <div class="alert alert-warning opaque" role="alert" id="cookiesbox" *ngIf="showCookiesBox === 'true'">
        <p>
            Éste sitio web usa cookies, si permaneces aquí estarás aceptando su uso.
            Puede leer más sobre el uso de cookies en nuestra <a routerLink="/privacy-statement" target="_blank">política de privacidad</a>.
        </p>
        <button (click)="acceptCookies()" class="btn btn-primary mr-5">Si acepto</button>
        <button (click)="denyCookies()" class="btn btn-secondary" style="margin-left: 10px;">No acepto</button>
    </div>

    <!-- Whatsapp button start -->
    <div class="btn-whatsapp">
        <a href="https://api.whatsapp.com/send?phone=5493816116081" target="_blank">
        <img src="/assets/img/btn_whatsapp.png" alt="">
        </a>
    </div>
    

<h2 class="text-center text-tertiary">Mis especialidades</h2>
<p>Podés seleccionar la especialidad en que querés recibir trabajos. Estas especialidades se utilizan para que aparezcas en las búsquedas que hacen los empleadores. Podés agregar o quitar especialidades cuando gustes. Si quitas una especialidad, no aparecerás en las búsquedas relacionadas hasta que la agregues nuevamente.</p>
<!-- <app-categories-selector (categorySel)="addCategory($event)"></app-categories-selector> -->

<div class="row" *ngIf="categories && categories.length > 0">
    <div class="dropdown pb-4">
        <a href="#" class="d-flex align-items-center text-primary text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
            Clik aquí para agregar...
        </a>
        <ul class="pointer dropdown-menu dropdown-menu-primary text-small shadow">
            <li *ngFor="let cat of categories"><a class="dropdown-item" (click)="addCategory(cat)">{{ cat.name }}</a></li>
        </ul>
    </div>
</div>
<div class="row mt-2">
    <div class="table-responsive-md">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Especialidad</th>
                    <th scope="col" *ngIf="isDesktop || isTablet">Descripción</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let cat of supplierCategories">
                    <th scope="row">
                        <!-- <img src="./assets/img/{{cat.image}}" width="30px" alt="">  -->
                    </th>
                    <td>{{ cat.name }}</td>
                    <!-- <td colspan="2">
                        <button class="btn btn-outline-primary block">
                            <img src="./assets/img/{{cat.image}}" width="30px" alt=""> 
                            {{ cat.name }}
                        </button>
                    </td> -->
                    <td *ngIf="isDesktop || isTablet">{{ cat.description }}</td>
                    <td>
                        
                        <button type="button" class="btn btn-outline-light" ngbTooltip="Quitar" (click)="removeCategory(cat)"
                            style="color: var(--danger);">
                            <i class="bi bi-x-circle"></i>
                        </button>
                        <button type="button" class="btn btn-outline-light" ngbTooltip="{{ cat.description }}"
                            style="color: var(--tertiary);">
                            <i class="bi bi-info-circle"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12">
        <button class="btn btn-primary py-3 px-5" type="submit" (click)="finish()">
            Finalizar
        </button>
    </div>
</div>


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { Post } from '../interfaces/interfaces';
import { UsersService } from './users.service';
import { FilesService } from './files.service';

const URL2 = environment.url2;

@Injectable({
  providedIn: 'root'
})
export class PostsService {
  paginaPosts = 0;
  uploadedImages = 0;
  postId = '';

  constructor(private http: HttpClient,
              private userService: UsersService,
              private fileService: FilesService) { }

  createPost(post: Post, images: string[]) {
    // console.log(images);
    if ( post.coords == null ) {
      post.coords = '';
    }
    // console.log(images);
    const authorization = this.userService.getUsuario().token;
    const headers = new HttpHeaders({
      Authorization: authorization
    });
    const formdata = new FormData();
    formdata.append('mensaje', post.mensaje);
    formdata.append('img', '');
    formdata.append('coords', post.coords);
    formdata.append('especialidad', post.categoria);

    const api = URL2 + 'appposts';
    return new Promise( resolve => {
      this.http.post(api , formdata , { headers } )
        .subscribe( res => {
          this.postId  = res['data']['id'];
          // Emite el nuevo post para que lo reciba la lista de posts del tab1
          // this.nuevoPost.emit( resp['data'] );
          if (images.length > 0) {
            this.uploadedImages = 0;
            images.forEach( image => {
              const img = image[0]['name'].toString();
              const imageName = 'img-' + Date.now().toString() + '-' + img.substring(img.lastIndexOf('/') + 1);
              console.log(imageName);
              console.log(image[0]);
              const file = image[0];
              
              // const fileBlob: any = window.URL.createObjectURL();

              const formData: FormData = new FormData();
              formData.append('file', file , imageName);
              formData.append('path', '/'+this.postId);

              this.fileService.uploadFile(formData)
                .then(res => {
                  console.log(res);
                  
                }).catch(e=>{
                  console.log(e);
                  
                })
              // this.subirImagen(image, this.postId, '')
              //   .then( resImage => {
              //     this.uploadedImages++;
              //     this.updloadedImage.emit(this.postId);
              //   });
            });
          }
          resolve(res);
        } );
    } );
  }
}

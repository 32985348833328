import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-supplier-profile',
  templateUrl: './supplier-profile.component.html',
  styleUrls: ['./supplier-profile.component.css']
})
export class SupplierProfileComponent implements OnInit {
  menu = 1;
  menuItems = [
    {id:1, name: 'Datos personales', activeClass: 'active', iconClass: 'fs-4 bi-house', ariaCurrent: 'page', active:true},
    {id:2, name: 'Especialidades', activeClass: '', iconClass: 'fs-4 bi-person', ariaCurrent:'page', active:true},
    {id:3, name: 'Experiencia', activeClass: '', iconClass: 'fs-4 bi-patch-check', ariaCurrent:'page', active:false},
    {id:4, name: 'Trabajos realizados', activeClass: '', iconClass: 'fs-4 bi-bag', ariaCurrent:'page', active:false},
  ];

  constructor() { }

  ngOnInit(): void {
  }

  selectItem(id){
    this.menu = id;
    this.menuItems.forEach(item=>{
      item.activeClass = '';
      if (item.id === id) {
        item.activeClass = 'active';
      }
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { LoginComponent } from '../../auth/login/login.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../../services/users.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.css']
})
export class HeaderUserComponent implements OnInit {

  cookiesAccepted: string;
  showCookiesBox: string;
  userName: string;
  loggedUser = false;
  myDate: Date;
  homeLink = '/home';

  constructor(private ngModalService: NgbModal,
              private userService: UsersService,
              private router: Router) { }

  ngOnInit(): void {
    this.myDate = new Date();
    this.cookiesAccepted = localStorage.getItem('cookiesStatus');

    if (localStorage.getItem('userName')) {
      this.userName = localStorage.getItem('userName');
      this.loggedUser = true;
      this.homeLink = '/supplier-sidemenu';
    } else {  
      this.login();
    }

    if (!this.cookiesAccepted || this.cookiesAccepted === 'false') {
      this.showCookiesBox = 'true';
    } else {
      this.showCookiesBox = 'false';
    }
  }

  acceptCookies(){
    localStorage.setItem('cookiesStatus', 'true');
    this.cookiesAccepted = 'true';
    this.showCookiesBox = 'false';
  }

  denyCookies(){
    localStorage.setItem('cookiesStatus', 'false');
    this.cookiesAccepted = 'false';
    this.showCookiesBox = 'false';
  }

  login(){
    // const ref = this.ngModalService.open(LoginComponent, {size:'lg', centered: true, scrollable: true});
    // ref.componentInstance.link = 'supplier-sidemenu';
    this.router.navigate(['/login-full', {link:'supplier-sidemenu'}]);
  }

  logout(){
    this.userService.logout();
    // window.location.reload();
    this.router.navigate(['/home']);
  }

  changePass(){

  }

  reportIncident(){
    
  }

}

import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../../services/users.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  alertUser = false;
  alertOK = false;
  alertError = false;
  email = '';
  errorMessage = '';
  constructor(private userService: UsersService) { }

  ngOnInit(): void {
  }

  process(){
    this.userService.resetpass(this.email)
      .subscribe(res=>{
        console.log(res);
        if (res['code'] === '200') {
          this.alertOK = true;
        } else {
          this.alertError = true;
          this.errorMessage = res['msg'];

        }
        
      })
  }

}

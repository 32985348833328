<div class="modal-header bg-tertiary transparent-90">
    <h4 class="modal-title text-light" id="modal-title">{{ title }}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <!-- <p><strong>Está seguro de {{ action }} al {{ elementType }}<span class="text-primary">{{ elementName }}</span> ?</strong></p> -->
    <p>{{ text1 }}</p>
    <p>{{ text2 }}</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.dismiss('cancel click')">Cancelar</button>
    <button type="button" class="btn btn-tertiary" (click)="modal.close('yes')">Aceptar</button>
</div>


import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';

const URL2 = environment.url2;

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private http: HttpClient) { }

  uploadFile(formData) {
    const token = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token
      })
    };
    const httpHeaders = new HttpHeaders({
      Authorization: token
    });
    // const api = URL + 'companies/update/' + company.id;
    const api = URL2 + 'uploadFile';
    // console.log(httpOptions);
    return new Promise( resolve => {
      this.http.post(api, formData, {
        reportProgress: true,
        observe: 'events',
        headers: httpHeaders
        })
        .subscribe(resp => {
          if (resp.type === HttpEventType.UploadProgress) {
              // console.log('Upload progress:' + Math.round(resp.loaded / resp.total * 100)  + ' %');
          } else if (resp.type === HttpEventType.Response) {
            // console.log(resp);
            resolve(resp.body);
          }
        });
      });

  }
}

<app-header></app-header>

    <!-- Register Start -->
    <div
      class="container-fluid appointment py-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div class="container py-5">
        <div class="row g-5">
          <div class="col-lg-5 col-md-6 wow fadeIn" data-wow-delay="0.3s">
            <div class="border-start border-5 border-primary ps-4 mb-5">
              <h6 class="text-white text-uppercase mb-2">Registráte</h6>
              <h1 class="display-6 text-white mb-0">
                Formá parte de la comunidad Particulares.
              </h1>
            </div>
            <p class="text-white mb-0">
              Dejános tus datos y te enviaremos mas información.
            </p>
          </div>

          

          <div class="col-lg-7 col-md-6 wow fadeIn" data-wow-delay="0.5s">
            <form>
              <div class="row g-3">
                <div class="col-sm-12">
                  <!-- Register Success -->
                  <div class="alert alert-success" role="alert" *ngIf="successRegister">
                    <!-- <p style="margin-left: 10px;">Registración exitosa. </p>  -->
                    <p style="margin-left: 10px;">Gracias por tu registración, tomaremos 48 horas para validar tu información y te estaremos contactando.</p>
                  </div>

                  <!-- Register Error -->
                  <div class="alert alert-danger text-center" style="height: 100px ;" role="alert" *ngIf="errorRegister">
                    <p style="margin-left: 10px;">{{ errorMessage }}. </p> 
                    <!-- <p style="margin-left: 10px;">Intenta nuevamente por favor.</p> -->
                    <a class="btn btn-primary" routerLink="/login-full" *ngIf="enableLogin">Ingresar con correo electrónico y contraseña</a>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-floating">
                    <input
                      type="text"
                      name="userName"
                      [(ngModel)]="userName"
                      class="form-control border-0 transparent-90"
                      id="userName"
                      placeholder="Tu nombre"
                      [disabled]="successRegister"
                    />
                    <label for="userName">Tu nombre y apellido</label>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-floating">
                    <input
                      type="text"
                      name="userDNI"
                      [(ngModel)]="userDNI"
                      class="form-control  border-0 transparent-90"
                      id="userDNI"
                      placeholder="Tu DNI"
                      [disabled]="successRegister"
                    />
                    <label for="userDNI">Tu DNI</label>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-floating">
                    <input
                      type="email"
                      name="userEmail"
                      [(ngModel)]="userEmail"
                      class="form-control border-0 transparent-90"
                      id="userEmail"
                      placeholder="Tu correo electrónico"
                      [disabled]="successRegister || noEmail"
                      
                    />
                    <label for="userEmail">Tu correo electrónico (Opcional)</label>
                  </div>
                  <div class="form-check">
                    <input
                        name="noEmail"
                        [(ngModel)]="noEmail"
                        class="form-check-input" 
                        type="checkbox" 
                        value="" 
                        id="noEmail"
                        [disabled]="successRegister"
                        >
                    <label class="form-check-label" for="noEmail">
                        No tengo correo electrónico 
                    </label>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-floating">
                    <input
                      type="text"
                      name="userPhone"
                      [(ngModel)]="userPhone"
                      class="form-control border-0 transparent-90"
                      id="userPhone"
                      placeholder="Tu celular"
                      [disabled]="successRegister"
                    />
                    <label for="userPhone">Tu celular</label>
                  </div>
                </div>
              
                <!-- Legal terms -->
                <div class="col-md-12">         
                  <div class="alert alert-danger" role="alert" *ngIf="alertTerms">
                    <p style="margin-left: 10px;">Debes aceptar Términos y Condiciones</p> 
                  </div>
                  <div class="form-check mt-2">
                    <input
                        name="custTerms"
                        [(ngModel)]="supplierLegalTermsAccepted"
                        class="form-check-input" 
                        type="checkbox" 
                        value="" 
                        id="supplierLegalTermsAccepted"
                        [disabled]="successRegister"
                        >
                    <label class="form-check-label" for="supplierLegalTermsAccepted">
                        He leido y acepto la <a routerLink="/privacy-statement" target="_blank">Política de privacidad</a> y los <a routerLink="/legal-terms" target="_blank">Téminos y condiciones</a> 
                    </label>
                  </div>
                </div>

                <div class="col-12">
                  <button class="btn btn-primary w-100 py-3" type="submit" (click)="process()" *ngIf="!successRegister">
                    Registrarme
                  </button>
                </div>

                
                
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Register End -->


    <app-footer></app-footer>

